<template>
<!-- mobile trustpilot/signup/offers/payment section -->
<div class="mb-trustpilotSecBg web-newTrustpilotSecHide">
    <div class="d-flex justify-content-center">
        <a href="https://www.trustpilot.com/review/raffolux.com">
            <img src="https://raffolux-static.s3.eu-west-2.amazonaws.com/static/website/redev_images/newAllRaffTrustpilotLightTheme.svg" class="mb-newTrustpilotLightImg" alt="">
            <img src="https://static.raffolux.com/static/website/redev_images/raffleNewTrustImg.svg" class="mb-newTrustpilotDarkImg" alt="">
        </a>
    </div>
    <p class="mb-newSupportTxt">Supported payment options</p>
    <div class="d-flex justify-content-center mb-supportSecImgs">
        <a>
            <img src="https://static.raffolux.com/static/website/redev_images/Visa.svg" alt="">
        </a>
        <a>
            <img src="https://static.raffolux.com/static/website/redev_images/Mastercard.svg" alt="">
        </a>
        <a>
            <img src="https://static.raffolux.com/static/website/redev_images/PayPal.svg" alt="">
        </a>
        <a>
            <img src="https://static.raffolux.com/static/website/redev_images/Google+Pay.svg" alt="">
        </a>
        <a>
            <img src="https://static.raffolux.com/static/website/redev_images/ApplePay.svg" alt="">
        </a>
    </div>
    <!-- <div v-if="!userSession" class="d-flex justify-content-center">
        <button type="button" class="btn mb-signUpOffBtn" @click="mobile_signup_modal_selection()"> <i class="fa-solid fa-gift mb-newSignUpGift"></i> SIGN UP OFFERS</button>
    </div> -->
</div>

<!-- mobile homePrize -->

<!-- <div class="row winners-sec m-0">
    <div class="mb-prize-value col p-0">
        <span v-if="featuredstatistics.PrizesWon == []">
            <h1 class="mb-0">won_amount</h1>
            <h4 class="mb-0">was not passed</h4>
        </span>
        <span v-else>
            <img :src="`${s3Url}redev_images/home-signedout-dark-mob-carousel-shape-17%402x.png`" alt="" class="mb-gift-img" />
            <h1 class="mb-0">
                £{{convertToMillion(featuredstatistics.PrizesWon)}}M
            </h1>
            <h4 class="mb-0">won in prizes</h4>
        </span>
    </div>
    <div class="col p-0 text-center">
        <span v-if="featuredstatistics.RaffleWinners == []">
            <img :src="`${s3Url}redev_images/2023-v4-home-signedin-dark-shape-42%402x.png`" alt="" class="mb-trophy-img">
            <h3>winner_amount</h3>
            <h5>was not passed</h5>
        </span>
        <span v-else>
            <img :src="`${s3Url}redev_images/2023-v4-home-signedin-dark-shape-42%402x.png`" alt="" class="mb-trophy-img">
            <h3>{{ convert_tolocalstring_data(featuredstatistics.RaffleWinners) }}</h3>
            <h5>winners</h5>
        </span>
    </div>
    <div class="col p-0 text-center">
        <span v-if="featuredstatistics.RaisedCharity == []">
            <img :src="`${s3Url}redev_images/home-signedout-dark-mob-shape-18%402x.png`" alt="" class="mb-ribbon-img">
            <h3>raised_amount</h3>
            <h5>was not passed</h5>
        </span>
        <span v-else>
            <img :src="`${s3Url}redev_images/home-signedout-dark-mob-shape-18%402x.png`" alt="" class="mb-ribbon-img" />
            <h3>£{{ Math.round(featuredstatistics.RaisedCharity/1000) }}k</h3>
            <h5>donated</h5>
        </span>
    </div> -->
<!-- <div class="col p-0 text-center">
        <span>
            <img :src="`${s3Url}redev_images/person_icon.png`" alt="" class="mb-gift-img" />
            <h3>150+</h3>
            <h5>winners a day</h5>
        </span>
    </div> -->

<!-- </div> -->
<!-- web homePrize -->
<div class="page-content-wrapper">
    <div class="row winners-details align-items-center m-0 justify-content-between">
        <div class="col-md-3">
            <span class="d-flex">
                <img src="" alt="" />
                <span v-if="prizes_Mln.PrizesWon == ''">
                    <h4 class="valueTxt mb-0">won_amount</h4>
                    <h3 class="me-0">was not passed</h3>
                </span>
                <span v-else>
                    <h4 class="valueTxt mb-0">
                        £
                        <span v-if="prizes_Mln.PrizesWon">{{ convertToMillion(prizes_Mln.PrizesWon)}}</span> Million
                    </h4>
                    <!-- <h4 class="valueTxt_mb mb-0">
                        £{{ convertToMillion(prizes_Mln.PrizesWon)}}M
                    </h4> -->
                    <h3 class="me-0">won in prizes so far</h3>
                </span>
            </span>
        </div>
        <div class="col-md-2 p-0">
            <span class="d-flex align-items-center">
                <img :src="s3Url + 'redev_images/2023-v4-home-signedin-dark-shape-42%402x.png'" alt="" class="desk-trophy-img">
                <h4><span v-if="raffleWinners.RaffleWinners">{{ convert_tolocalstring_data(raffleWinners.RaffleWinners) }}</span> raffle winners and counting</h4>
            </span>
        </div>
        <div class="col-md-2">
            <span class="d-flex align-items-center">
                <img :src="s3Url + 'redev_images/home-signedout-dark-mob-carousel-shape-17%402x.png'" alt="" class="desk-gift-img">
                <h1 style="font-size: 16px;">Instant Win Competitions</h1>
            </span>
        </div>
        <div class="col-md-2">
            <span class="d-flex align-items-center">
                <img :src="s3Url + 'redev_images/home-signedout-dark-mob-shape-18%402x.png'" alt="" class="desk-ribbon-img">
                <h4>£{{ Math.round(raisedCharity.RaisedCharity/1000) }}K donated to UK charities</h4>
            </span>
        </div>
        <div class="col-md-2 pe-0">
            <span class="d-flex align-items-center">
                <img :src="s3Url + 'redev_images/instant_prize_logo.png'" alt="" class="desk-bolt-img">
                <h4>Instant prize claims & fast payouts</h4>
            </span>
        </div>
    </div>
</div>
</template>

<script>
import {
    mapGetters,
    mapActions

} from 'vuex';
import {
    socialImgUrlLink
} from '@/s3bucket.js';
import {
    apiURL
} from "@/api/allApis"

export default {
    data: () => ({
        // featuredstatistics: [],
        s3Url: socialImgUrlLink,
        prizes_Mln: '',
        raffleWinners: '',
        raisedCharity:'',
        // userSession: "",
    }),
    computed: {
        ...mapGetters(['getPrizeWon']),
        ...mapGetters(['getRaffleWinner']),
        ...mapGetters(['getRaisedCharity']),
        
    },

    created() {
        
        this.fetchPrizesWon();
        this.fetchRaffleWinners();
        this.fetchRaisedCharity();
    },
    methods: {

        ...mapActions(['fetch_prizes_won']),
        async fetchPrizesWon() {
            await this.fetch_prizes_won();
            this.prizes_Mln = this.getPrizeWon;            
        },
        ...mapActions(['fetch_raffle_winner']),
        async fetchRaffleWinners() {
            await this.fetch_raffle_winner();
            this.raffleWinners = this.getRaffleWinner;            
        },
        ...mapActions(['fetch_raised_charity']),
        async fetchRaisedCharity() {
            await this.fetch_raised_charity();
            this.raisedCharity = this.getRaisedCharity;           
        },

        mobile_signup_modal_selection() {
            dataLayer.push({
                'event': 'sign_up_accessed'
            });
            this.$router.push("/signUp/");
            this.$emit('call-clear-function'); //emit to mobileNewLoginSignUp.vue to clear the fields
        },

        convertToMillion(num) {
            const units = ["Million", "B", "T", "Q"];
            const unit = Math.floor((num / 1.0e1).toFixed(0).toString().length);
            const r = unit % 3;
            const x = Math.abs(Number(num)) / Number("1.0e+" + (unit - r)).toFixed(2);
            return x.toFixed(1);
        },
      
        convert_tolocalstring_data(dataIn) {
            return Number(dataIn).toLocaleString();
        },
    },
};
</script>
