// import Cookies from 'js-cookie';

export default {

    data() {
        return {
            platform: 'Unknown'
        };
    },

    created() {
        this.checkPlatformAndUpdate();
    },

    methods: {
        isMobileApp() {
            return /gonative/.test(navigator.userAgent.toLowerCase());
        },
        detectPlatform() {
            const userAgent = navigator.userAgent || navigator.vendor || window.opera;
            if (/android/i.test(userAgent)) {
                return 'Android';
            } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
                return 'iOS';
            }
            return 'Unknown';
        },

        checkPlatformAndUpdate() {           

            if (this.isMobileApp()) {
                this.platform = this.detectPlatform();
                if(this.platform == 'Android'){
                    this.$router.push('/androidRoute');
                }else if(this.platform == 'iOS'){
                    this.$router.push('/iosRoute');
                }
                // this.$forceUpdate();
            } else {
              
                
            }
        },
    }
};
