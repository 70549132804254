<template>
<div class="footer-bg footer-bg-mb">
    <div class="footer-content footer-desk">
        <div class="row align-items-center border_bottom">
            <div class="col-md-4 text-center">

            </div>
            <div class="col-md-4 d-flex gamble_img_sec p-0">
                <a href="https://apps.apple.com/gb/app/raffolux/id1624651505">
                    <img src="https://static.raffolux.com/static/website/redev_images/appstore.svg" class="footerGooglePlayImg" alt="">
                </a>
                <a href="https://play.google.com/store/apps/details?id=com.raffolux.app">
                    <img src="https://static.raffolux.com/static/website/redev_images/googleplay.svg" class="footerAppstoreImg" alt="">
                </a>

            </div>
            <div class="col-md-4 text-center">
                <img src="https://raffolux-static.s3-eu-west-2.amazonaws.com/static/website/media23/begambleaware.png" alt="" class="plus_img">
            </div>
        </div>
        <div class="row align-items-center link_border">
            <div class="col-md-4">
                <a href="https://www.trustpilot.com/review/raffolux.com"> <img :src="`${s3Url}/newTrustPilotHomePage.svg`" alt=""></a>
            </div>
            <div class="col-md-4">
                <h3>
                    Payments we accept
                </h3>
                <span class="d-flex justify-content-center footerSignIns">
                    <a href="#"><img :src="`${s3Url}/Visa.svg`" alt=""></a>
                    <a href="#"><img :src="`${s3Url}/Mastercard.svg`" alt=""></a>
                    <a href="#"><img :src="`${s3Url}/PayPal.svg`" alt=""></a>
                    <a href="#"><img :src="`${s3Url}/Google+Pay.svg`" alt=""></a>
                    <a href="#"><img :src="`${s3Url}/ApplePay.svg`" alt=""></a>
                    <a href="#"><img :src="`${s3Url}/americanExpImg.svg`" alt=""></a>

                </span>
            </div>
            <div class="col-md-4">
                <h3>
                    Follow us
                </h3>
                <span class="d-flex justify-content-center" style="gap:15px">
                    <a href="https://www.facebook.com/raffolux/"><img :src="`${s3Url}/Facebook+-+Negative.svg`" alt=""></a>
                    <a href="https://www.instagram.com/raffolux/"><img :src="`${s3Url}/Instagram+-+Negative.svg`" alt=""></a>
                    <a href="https://twitter.com/i/flow/login?redirect_after_login=%2Fraffolux%2F"><img :src="`${s3Url}/Twitter+-+Negative.svg`" alt=""></a>
                    <a href="https://www.linkedin.com/uas/login?session_redirect=https%3A%2F%2Fwww.linkedin.com%2Fcompany%2Fraffolux%2Fabout%2F"><img :src="`${s3Url}/LinkedIn+-+Negative.svg`" alt=""></a>
                </span>
            </div>
        </div>
        <div class="row align-items-center company_links">
            <div class="col-md-4 position-relative p-0">
                <img :src="`${s3Url}/raffolux-white.svg`" alt="" class="footer_logo">
                <img @click="gambleaware()" :src="`${s3Url}/new18%2BImg.svg`" alt="" class="newFooter18plusImg">
                <img :src="`${s3Url}/FundraisingReg.svg`" alt="" class="newFundRaiseImg">
            </div>
            <div class="col-md-3">
                <h4>Website</h4>
                <ul class="list-unstyled p-0 footer-links">
                    <li>
                        <a data-bs-toggle="modal" data-bs-target="#newBonusSignupModal" @click="signUp()">My Raffles</a>
                    </li>
                    <li><a @click="call_newslist()">News & Blog</a></li>
                    <li><a data-bs-toggle="modal" data-bs-target="#newBonusSignupModal" @click="signUp()">Account</a>
                    </li>
                    <li> <a @click="call_play()">Responsible Play</a></li>
                    <li><a @click="call_winners_gallery()">Winners Gallery</a></li>
                    <li><a @click="call_Sitemap()">Sitemap</a></li>
                </ul>

            </div>
            <div class="col-md-2">
                <h4>Categories</h4>
                <ul class="list-unstyled p-0 footer-links">
                    <li>
                        <a @click="goto_category('instant-win')">Instant Win</a>
                    </li>
                    <li>
                        <a @click="goto_category('cash')">Cash</a>
                    </li>
                    <li><a @click="goto_category('electronic')">Tech</a></li>
                    <li><a @click="goto_category('car')">Cars</a></li>
                    <li> <a @click="goto_category('holiday')">Holidays</a></li>
                    <li><a @click="goto_category('indulgence')">Indulgence</a></li>
                </ul>

            </div>
            <div class="col-md-3">
                <div>
                    <h4>Contact</h4>
                    <h5>
                        Raffolux Ltd, 4 Ravey Street, London, EC2A 4QP

                    </h5>
                    <span class="d-flex align-items-center">
                        <a href="tel:02039297496" class="text-decoration-none footerCnctSec">
                            <img :src="`${s3Url}/phone-solid.svg`" alt="">
                            <h5 class="mb-0">020 3929 7496</h5>
                        </a>
                    </span>
                    <span class="d-flex align-items-center">
                        <a href="mailto:support@raffolux.com" class="text-decoration-none footerCnctSec">
                            <img :src="`${s3Url}/envelope-solid.svg`" alt="">
                            <h5 class="mb-0">support@raffolux.com</h5>
                        </a>
                    </span>
                </div>

            </div>
        </div>
        <div class="row align-items-center policy-sec">
            <div class="col-md-7">
                Copyright © 2019 - 2024 Raffolux Ltd. All rights reserved. Company No. 10962686
            </div>
            <div class="col-md-5 text-center">
                <span>

                    <a @click="call_privacy()" class="cursor-pointer">
                        Privacy Policy
                    </a>
                    <a @click="call_termsAndCondition()" class="me-4 cursor-pointer">
                        Terms of Service
                    </a>
                    <a @click="call_complaints()" class="cursor-pointer">
                        Complaints
                    </a>
                </span>
            </div>
        </div>
    </div>
    <div class="footer-mb">
        <div class="text-center img-sec">
            <span class="d-flex mb-gamble-img align-items-center">
                <img src="https://raffolux-static.s3-eu-west-2.amazonaws.com/static/website/media23/begambleaware.png" alt="" class="plus_img">
            </span>
            <a href="https://www.trustpilot.com/review/raffolux.com">
                <img :src="`${s3Url}/trustImg.svg`" alt="" class="mb-5">
            </a>

        </div>
        <div class="mb-link-sec text-center">
            <span>
                <h3>
                    Payments we accept
                </h3>
                <span class="d-flex mb-payment_links justify-content-center">
                    <img :src="`${s3Url}/Visa.svg`" alt="">
                    <img :src="`${s3Url}/Mastercard.svg`" alt="">
                    <img :src="`${s3Url}/PayPal.svg`" alt="">
                    <img :src="`${s3Url}/Google+Pay.svg`" alt="">
                    <img :src="`${s3Url}/ApplePay.svg`" alt="">
                </span>
            </span>
            <span class="mb-social_links">
                <h3>
                    Follow us
                </h3>
                <span class="d-flex justify-content-center">
                    <a href="https://www.facebook.com/raffolux/"><img :src="`${s3Url}/Facebook+-+Negative.svg`" alt=""></a>
                    <a href="https://www.instagram.com/raffolux/"><img :src="`${s3Url}/Instagram+-+Negative.svg`" alt=""></a>
                    <a href="https://twitter.com/i/flow/login?redirect_after_login=%2Fraffolux%2F"><img :src="`${s3Url}/Twitter+-+Negative.svg`" alt=""></a>
                    <a href="https://www.linkedin.com/uas/login?session_redirect=https%3A%2F%2Fwww.linkedin.com%2Fcompany%2Fraffolux%2Fabout%2F"><img :src="`${s3Url}/LinkedIn+-+Negative.svg`" alt=""></a>

                </span>
            </span>
            <div class="mb-logo">
                <img :src="`${s3Url}/raffolux-white.svg`" alt="">
            </div>
            <div class="d-flex flex-column align-items-center">
                <img @click="gambleaware()" :src="`${s3Url}/new18%2BImg.svg`" alt="" class="mbNew18PlusImg">
                <img :src="`${s3Url}/FundraisingReg.svg`" alt="" class="mbNewFundRaisingImg">
            </div>
            <div class="row mb-web_cat_links">
                <div class="col">
                    <h4>Website</h4>
                    <ul class="mobile-footer-links p-0 list-unstyled">
                        <li><a @click="call_signUp()">My Raffles</a></li>
                        <li><a @click="call_newslist()">News &amp; Blog</a></li>
                        <li><a @click="call_signUp()">Account</a></li>
                        <li><a @click="call_play()">Responsible Play</a></li>
                        <li><a @click="call_winners_gallery()">Winners Gallery</a></li>
                        <li><a @click="call_Sitemap()">Sitemap</a></li>
                    </ul>

                </div>
                <div class="col">
                    <h4>Categories</h4>
                    <ul class="mobile-footer-links p-0 list-unstyled">
                        <li><a @click="goto_category('instant-win')">Instant Win</a></li>
                        <li><a @click="goto_category('cash')">Cash</a></li>
                        <li><a @click="goto_category('electronic')">Tech</a></li>
                        <li><a @click="goto_category('car')">Cars</a></li>
                        <li><a @click="goto_category('holiday')">Holidays</a></li>
                        <li><a @click="goto_category('indulgence')">Indulgence</a></li>
                    </ul>
                </div>
            </div>
            <div class="text-center contact-sec">
                <h3>
                    Contact
                </h3>
                <h4>
                    Raffolux Ltd, 4 Ravey Street, London, EC2A 4QP
                </h4>
                <span class="d-flex align-items-center justify-content-center">
                    <img :src="`${s3Url}/phone-solid.svg`" alt="">
                    <h5 class="mb-0"><a href="tel:02039297496" class="text-decoration-none">020 3929 7496</a></h5>
                </span>
                <span class="d-flex align-items-center justify-content-center">
                    <img :src="`${s3Url}/envelope-solid.svg`" alt="">
                    <h5 class="mb-0"><a href="mailto:support@raffolux.com" class="text-decoration-none">support@raffolux.com</a></h5>
                </span>
            </div>
        </div>
        <div class="text-center copyright-sec">
            <p>
                Copyright © 2019 - 2024 Raffolux Ltd. All rights reserved. Company No. 10962686
            </p>
            <span class="d-flex justify-content-center gap-4 pb-5">
                <a @click="call_privacy()">Privacy Policy</a>
                <a @click="call_termsAndCondition()">Terms of Service</a>
                <a @click="call_complaints()">Complaints</a>
            </span>
        </div>
    </div>
</div>
</template>

<script>
import {
    socialImgUrlLink
} from '@/s3bucket.js';
export default {
    data: () => ({
        s3Url: socialImgUrlLink + "redev_images",
        categoryType: '',
    }),
    props: ['categoryData'],
    methods: {

        call_signUp() {
            dataLayer.push({
                'event': 'sign_up_accessed'
            });
            this.$router.push({
                name: "mobileSignUp",
            });
        },

        signUp() {
            dataLayer.push({
                'event': 'sign_up_accessed'
            });
            $("#newBonusSignupModal").modal("show");
        },

        goto_category(category) {
            this.categoryType = category;
            this.$router.push({
                name: 'categoryNew',
                params: {
                    categoryData: this.categoryType
                }
            }).then(() => {
                location.reload();
            })
        },

        call_newslist() {
            this.$router.push("/newslist/")
        },

        call_play() {
            this.$router.push("/play/")
        },

        call_winners_gallery() {
            this.$router.push({
                name: 'winnerGalleryWithoutLogin'
            });

        },
        call_Sitemap() {
            if (process.env.VUE_APP_ENV === 'dev') {
                window.location.href = 'https://dev-new.raffolux.com/sitemap.xml';
            } else if (process.env.VUE_APP_ENV === 'uat') {
               window.location.href = 'https://uat.raffolux.com/sitemap.xml';
            } else if (process.env.VUE_APP_ENV === 'testing') {
                 window.location.href= 'https://testing.d21ajyco9f10q5.amplifyapp.com/sitemap.xml';
            } else if (process.env.VUE_APP_ENV === 'preprod') {
                window.location.href = 'https://preprod.raffolux.com/sitemap.xml';
            } else if (process.env.VUE_APP_ENV === 'prod') {
                window.location.href = 'https://raffolux.com/sitemap.xml';
            }
        },

        call_privacy() {
            this.$router.push("/privacy/")
        },
        call_complaints() {
            this.$router.push("/complaints/")
        },
        call_termsAndCondition() {
            this.$router.push("/tcs/")
        },

        gambleaware(){
            window.location.href = "https://www.gambleaware.org/"
        }

    },

}
</script>
