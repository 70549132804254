import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/index.js'
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'
import Vue from 'vue';
import platformMixin from './mixins/platformMixin';

loadFonts()
const app = createApp(App);
app.mixin(platformMixin);
app.use(router)
  .use(store)
  .use(vuetify)
  .mount('#app');
