export const fetch_prizeclaim_count = {
    data: () => ({
      prizeClaimCount: "",
    }),
    async created() {
     
      this.userSession = localStorage.getItem("user_session");
      if (this.userSession) {
        await this.fetch_prizeclaim_count(this.userSession);
      } else {
        localStorage.removeItem("user_session");
        this.$router.push({
          name: "home"
      })
        
      }
    },
    methods: {
      async fetch_prizeclaim_count(userSession) {
        try {
          const response = await fetch(
            process.env.VUE_APP_API_URL + "/v1/fetchMyPrizeClaimsCountWithLogin",
            {
              method: "POST",
              headers: {
                "Content-type": "application/json; charset=UTF-8",
                Authorization: JSON.parse(userSession),
              },
            }
          );
          const data = await response.json();
          
          this.prizeClaimCount = data.winningTicketsCount;
         // console.log("ssss",this.prizeClaimCount)
        } catch (error) {
          
        }
      },
    },
  };
  