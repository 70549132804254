<template>

<div>
    <h2 class="mb-featureJackpotTxt">FEATURED JACKPOTS</h2>
</div>
<div class="mb-raffle-sec">
    <!-- Mobile Home Card -->
    <loader v-if="isLoading" class="loaderbg"></loader>
    <div v-else v-for="raffo in featuredRaffles" :key="raffo" class="each_raffle">
        <div>
            <div @click="call_button(raffo)" class="mb-card-sec position-relative" :style="{ backgroundImage: `url(${urlLink + (raffo.PromoImageUrl !== '' ? raffo.PromoImageUrl : raffo.MiniImageUrl)})`, }">
                <span v-if="raffo.is_launch_price == true" class="launch_price d-flex align-items-center">
                    <h4>£{{ raffo.discounted_entry_cost_gbp }}</h4>
                    <h5>£{{ raffo.entry_cost_gbp }}</h5>
                    <div class="price_bg">LAUNCH PRICE</div>
                </span>

                <div class="card-bottom-sec">
                    <span v-if="raffo.is_unlimited_raffle != true || raffo.custom_raffle_id == null" class="position-relative">
                        <h2>Sold {{ raffo.percentage_sold }}%</h2>

                        <div class="progress progress-square ">
                            <div class="progress-bar progress-square homeprogressMob position-absolute" role="progressbar" :style="{ width: raffo.percentage_sold + '%', }" aria-valuenow="20" aria-valuemin="0" aria-valuemax="100">
                                <div class="mob-homeProgressDot"></div>
                            </div>
                        </div>

                    </span>
                    <div class="row tickets_details">
                        <div class="col-3 pr-0">
                            <span>
                                <h5>Ticket price</h5>
                                <h4 v-if="raffo.is_launch_price == true">
                                    £{{ raffo.discounted_entry_cost_gbp }}
                                </h4>
                                <h4 v-else>£{{ raffo.entry_cost_gbp }}</h4>
                            </span>
                        </div>
                        <div class="col-4 text-center">
                            <span>
                                <h5>Raffle ends</h5>
                                <h3> {{ countdownTimers[raffo.id] }}</h3>
                            </span>
                        </div>

                        <div class="col-5 text-end">
                            <button type="button" class="btn play_btn" @click="call_button(raffo)">
                               PLAY
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- web home card -->

    <div class="page-content-wrapper">
        <div class=" homeFeatureTxtBg">
            <h2 class="homeFeaturedRaffTxt mb-0">FEATURED COMPETITIONS</h2>
            <!-- <button type="button" class="btn homeNewViewBtn">View all </button> -->
        </div>

        <loader v-if="isLoading" class="loaderbg"></loader>
        <div class="left-card loginCardSec m-0" v-else>
            <div v-if="isSignUpBonus" class="p-0">
                <div class="newHomeSignupBonusSecBg">
                    <div class="d-flex justify-content-center">
                        <img src="https://raffolux-static.s3.eu-west-2.amazonaws.com/static/website/redev_images/new20freeImg.png" alt="" class="newHomeTwentyPoundsImg">
                    </div>
                    <button type="button" class="btn newSignUpOffBtn p-0">NEW SIGN UP OFFER</button>
                    <p class="newSignUpDescTxt">Sign up today and <span class="newGetFreeTxt"> get £20 FREE</span></p>
                    <p class="newCompleteFreeTxt">When you spend £5</p>
                    <button type="button" class="btn newChooseBonusBtn p-0" data-bs-toggle="modal" data-bs-target="#newBonusSignupModal">SIGN UP</button>
                </div>
            </div>
            <div class="p-0" :style="{ width: isSignUpBonus ? '1040px' : '1304px' }">
                <v-carousel v-if="featuredRaffles.length" hide-delimiters show-arrows="hover" height="auto">
                    <template v-for="(raffo, i) in featuredRaffles" :key="i">
                        <template v-if="i === 0 || (i - 1) % 2 === 0 || i % 2 === 0">
                            <v-carousel-item :key="'full-' + i">
                                <v-layout row wrap>
                                    <!--full img of first card -->

                                    <div xs12 md6>
                                        <div class="cardBg-img" :style="{ backgroundImage: `url(${urlLink + (raffo.XLImageUrl.length ? raffo.XLImageUrl : raffo.MainImageUrl)})`, }">
                                            <span class="d-flex">
                                                <!-- new timer countdown -->
                                                <span v-if="countdownAbove7Day[raffo.id]">
                                                    <div class="newJcptPrzBg">
                                                        <h2><img src="https://raffolux-static.s3.eu-west-2.amazonaws.com/static/website/redev_images/newHomeCrownImg.svg" class="homeCrownIcon">{{ countdownAbove7Day[raffo.id] }}</h2>
                                                    </div>
                                                </span>

                                                <div v-if="countdownAbove1Day[raffo.id]">
                                                    <h6 class="newDrawFeatureTxt">{{ countdownAbove1Day[raffo.id] }}</h6>
                                                </div>

                                                <span v-if="raffo.is_timer_enabled == true && CountdownBellow1Day[raffo.id]">
                                                    <div class="newFeatureRaffEndingTimerBg">
                                                        <p class="mb-0">ENDING IN</p>
                                                        <h6 class="mb-0"> {{ CountdownBellow1Day[raffo.id] }}</h6>
                                                    </div>
                                                </span>
                                                <!-- new timer countdown -->
                                            </span>
                                            <div class="row newFeaturedRaffDtlsBg m-0 align-items-center">
                                                <div class="col-7 p-0">
                                                    <div class="newRafoTtl">{{ raffo.Title }}</div>
                                                    <div class="newraffProgressWidth">
                                                        <div v-if="raffo.is_unlimited_raffle != true || raffo.custom_raffle_id == null" class="progress progress-square">
                                                            <div class="progress-bar progress-square homeProgress" role="progressbar" :style="{ width: raffo.percentage_sold + '%', }" aria-valuenow="20" aria-valuemin="0" aria-valuemax="100">
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div v-if="raffo.is_unlimited_raffle != true || raffo.custom_raffle_id == null">
                                                        <h5>{{ raffo.total_entries_sold }} tickets sold</h5>
                                                    </div>
                                                </div>
                                                <div class="col-2">
                                                    <span v-if="raffo.is_launch_price == true" class="launch_price d-flex align-items-center flex-column">
                                                        <span class="d-flex align-items-center">
                                                            <h4>£{{ raffo.discounted_entry_cost_gbp }}</h4>
                                                            <h5>£{{ raffo.entry_cost_gbp }}</h5>
                                                        </span>
                                                        <p class="homeFeatureCardPerTkt">per ticket</p>
                                                        <div class="price_bg">LAUNCH PRICE</div>

                                                    </span>
                                                    <div v-else>
                                                        <h3>£{{ raffo.entry_cost_gbp }}</h3>
                                                        <p class="homeFeatureCardPerTkt">per ticket</p>
                                                    </div>

                                                </div>
                                                <div class="col-3 pe-0">
                                                    <button type="button" class="btn play-btn" @click="call_button(raffo)">
                                                        ENTER NOW
                                                        <img src="https://raffolux-static.s3.eu-west-2.amazonaws.com/static/website/redev_images/raffoluxIcon.svg" alt="" class="newRaffIcon">
                                                    </button>
                                                </div>

                                                <!-- <a href="/raffle" class="btn play-btn">PLAY NOW</a> -->

                                            </div>

                                        </div>
                                    </div>

                                    <!-- half img of second card -->

                                    <div xs12 md6 v-if="i + 1 < featuredRaffles.length">
                                        <div class="cardBg-img" :style="{ backgroundImage: `url(${urlLink + (featuredRaffles[i + 1].XLImageUrl ? featuredRaffles[i + 1].XLImageUrl : featuredRaffles[i + 1].MainImageUrl)})`, }">
                                            <span class="d-flex">
                                                <!-- new timer countdown -->
                                                <span v-if="countdownAbove7Day[featuredRaffles[i + 1].id]">
                                                    <div class="newJcptPrzBg">
                                                        <h2><img src="https://raffolux-static.s3.eu-west-2.amazonaws.com/static/website/redev_images/newHomeCrownImg.svg" class="homeCrownIcon">{{ countdownAbove7Day[featuredRaffles[i + 1].id] }}</h2>
                                                    </div>
                                                </span>

                                                <div v-if="countdownAbove1Day[featuredRaffles[i + 1].id]">
                                                    <h6 class="newDrawFeatureTxt">{{ countdownAbove1Day[featuredRaffles[i + 1].id] }}</h6>
                                                </div>

                                                <span v-if="featuredRaffles[i + 1].is_timer_enabled == true && CountdownBellow1Day[featuredRaffles[i + 1].id]">
                                                    <div class="newFeatureRaffEndingTimerBg">
                                                        <p class="mb-0">ENDING IN</p>
                                                        <h6 class="mb-0"> {{ CountdownBellow1Day[featuredRaffles[i + 1].id] }}</h6>
                                                    </div>
                                                </span>
                                                <!-- new timer countdown -->
                                            </span>
                                            <div class="row newFeaturedRaffDtlsBg m-0 align-items-center">
                                                <div class="col-7 p-0">
                                                    <div class="newRafoTtl">{{ featuredRaffles[i + 1].Title }}</div>
                                                    <div class="newraffProgressWidth">
                                                        <div v-if="featuredRaffles[i + 1].is_unlimited_raffle == false" class="progress progress-square" style=" height: 6px; max-height: 6px !important;  border-radius: 8px;  background-color: rgba(255, 189, 10, 0.2); ">
                                                            <div class="progress-bar progress-square homeProgress" role="progressbar" aria-valuenow="20" aria-valuemin="0" aria-valuemax="100" :style="{ width: featuredRaffles[i + 1].percentage_sold + '%', }">
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div v-if="featuredRaffles[i + 1].is_unlimited_raffle == false">
                                                        <h5>
                                                            {{ featuredRaffles[i + 1].total_entries_sold }}
                                                            tickets sold
                                                        </h5>
                                                    </div>
                                                </div>
                                                <div class="col-2">
                                                    <span v-if="featuredRaffles[i + 1].is_launch_price == true" class="launch_price d-flex align-items-center flex-column">
                                                        <span class="d-flex align-items-center">
                                                            <h4> £{{ featuredRaffles[i + 1].discounted_entry_cost_gbp }}
                                                            </h4>
                                                            <h5>£{{ featuredRaffles[i + 1].entry_cost_gbp }}</h5>
                                                        </span>
                                                        <p class="homeFeatureCardPerTkt">per ticket</p>
                                                        <div class="price_bg">LAUNCH PRICE</div>

                                                    </span>
                                                    <div v-else>
                                                        <h3>£{{ featuredRaffles[i + 1].entry_cost_gbp }}</h3>
                                                        <p class="homeFeatureCardPerTkt">per ticket</p>
                                                    </div>

                                                </div>
                                                <div class="col-3 pe-0">
                                                    <button type="button" class="btn play-btn" @click="call_button(featuredRaffles[i + 1])">
                                                        ENTER NOW
                                                        <img src="https://raffolux-static.s3.eu-west-2.amazonaws.com/static/website/redev_images/raffoluxIcon.svg" alt="" class="newRaffIcon">
                                                    </button>
                                                </div>

                                                <!-- <a href="/raffle" class="btn play-btn">PLAY NOW</a> -->

                                            </div>
                                        </div>
                                    </div>

                                    <div xs12 md6 v-else-if="featuredRaffles.length > 1">
                                        <div class="cardBg-img" :style="{ backgroundImage: `url(${urlLink + (featuredRaffles[0].XLImageUrl ? featuredRaffles[0].XLImageUrl : featuredRaffles[0].MainImageUrl)})` }">
                                            <span class="d-flex">
                                                <!-- new timer countdown -->
                                                <span v-if="countdownAbove7Day[featuredRaffles[0].id]">
                                                    <div class="newJcptPrzBg">
                                                        <h2><img src="https://raffolux-static.s3.eu-west-2.amazonaws.com/static/website/redev_images/newHomeCrownImg.svg" class="homeCrownIcon">{{ countdownAbove7Day[featuredRaffles[0].id] }}</h2>
                                                    </div>
                                                </span>

                                                <div v-if="countdownAbove1Day[featuredRaffles[0].id]">
                                                    <h6 class="newDrawFeatureTxt">{{ countdownAbove1Day[featuredRaffles[0].id] }}</h6>
                                                </div>

                                                <span v-if="featuredRaffles[0].is_timer_enabled == true && CountdownBellow1Day[featuredRaffles[0].id]">
                                                    <div class="newFeatureRaffEndingTimerBg">
                                                        <p class="mb-0">ENDING IN</p>
                                                        <h6 class="mb-0"> {{ CountdownBellow1Day[featuredRaffles[0].id] }}</h6>
                                                    </div>
                                                </span>
                                                <!-- new timer countdown -->
                                            </span>
                                            <div class="row newFeaturedRaffDtlsBg m-0 align-items-center">
                                                <div class="col-7 p-0">
                                                    <div class="newRafoTtl">{{ featuredRaffles[0].Title }}</div>
                                                    <div class="newraffProgressWidth">
                                                        <div v-if="featuredRaffles[0].is_unlimited_raffle == false" class="progress progress-square" style=" height: 6px;   max-height: 6px !important;  border-radius: 8px;  background-color: rgba(255, 189, 10, 0.2); ">
                                                            <div class="progress-bar progress-square homeProgress" role="progressbar" aria-valuenow="20" aria-valuemin="0" aria-valuemax="100" :style="{ width: featuredRaffles[0].percentage_sold + '%', }">
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div v-if="featuredRaffles[0].is_unlimited_raffle == false">
                                                        <h5>
                                                            {{ featuredRaffles[0].total_entries_sold }}
                                                            tickets sold
                                                        </h5>
                                                    </div>
                                                </div>
                                                <div class="col-2">
                                                    <span v-if="featuredRaffles[0].is_launch_price == true" class="launch_price d-flex align-items-center flex-column">
                                                        <span class="d-flex align-items-center">
                                                            <h4> £{{ featuredRaffles[0].discounted_entry_cost_gbp }}
                                                            </h4>
                                                            <h5>£{{ featuredRaffles[0].entry_cost_gbp }}</h5>
                                                        </span>
                                                        <p class="homeFeatureCardPerTkt">per ticket</p>
                                                        <div class="price_bg">LAUNCH PRICE</div>

                                                    </span>
                                                    <div v-else>
                                                        <h3>£{{ featuredRaffles[0].entry_cost_gbp }}</h3>
                                                        <p class="homeFeatureCardPerTkt">per ticket</p>
                                                    </div>

                                                </div>
                                                <div class="col-3 pe-0">
                                                    <button type="button" class="btn play-btn" @click="call_button(featuredRaffles[0])">
                                                        ENTER NOW
                                                        <img src="https://raffolux-static.s3.eu-west-2.amazonaws.com/static/website/redev_images/raffoluxIcon.svg" alt="" class="newRaffIcon">
                                                    </button>
                                                </div>
                                                <!-- <a href="/raffle" class="btn play-btn">PLAY NOW</a> -->
                                            </div>
                                        </div>
                                    </div>

                                </v-layout>
                            </v-carousel-item>

                        </template>
                    </template>
                </v-carousel>
                <!-- carousel ends -->

            </div>
        </div>
    </div>
</div>

<!-- Modal -->
<div class="modal fade modalBackdropCustom pr-0" id="chooseBonusModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog  modal-xl newChooseBonusModalBg">
        <div class="modal-content newChooseBonusModalBg">
            <div class="modal-body newChooseModalContentBg">
                <div class="d-flex justify-content-end">
                    <i class="fa-solid fa-xmark newChooseBonusCloseMark" data-bs-dismiss="modal" aria-label="Close"></i>
                </div>
                <h3 class="newChooseBonusTxt">Choose a bonus</h3>
                <p class="newChooseSubTxt">*name*, please select your FREE sign up bonus!</p>
                <div class="newSignupOffCardsSec">
                    <div class="newSignupOffCardBg">
                        <div class="newSignUpOffConfettiSec">
                            <img src="https://raffolux-static.s3.eu-west-2.amazonaws.com/static/website/redev_images/5poundsImg.svg" alt="" class="newSignUpOffFivePoundsImg">
                            <div class="newSignupOffConfetti">
                                <img src="https://raffolux-static.s3.eu-west-2.amazonaws.com/static/website/redev_images/newBonusConfettiImg.svg" alt="Confetti" class="newSignupOffConfettiImg">
                            </div>
                        </div>
                        <button type="button" class="btn newSignUpOfferBtn p-0">NEW SIGN UP OFFER</button>
                        <p class="newSignupTodayTxt mb-0">Sign up today and <span class="signUpTodayYellowTxt"> get £5 FREE</span></p>
                        <p class="newSignupNoCmpltFreeTxt">No catch, completely free!</p>
                        <button type="button" class="btn newOffChooseBonusBtn">CHOOSE BONUS</button>
                    </div>
                    <div class="newSignupOffCardBg">
                        <div class="newSignUpOffConfettiSec">
                            <img src="https://raffolux-static.s3.eu-west-2.amazonaws.com/static/website/redev_images/30Free.svg" alt="" class="newSignUpOffThirtyPoundsImg">
                            <div class="newSignupOffConfetti">
                                <img src="https://raffolux-static.s3.eu-west-2.amazonaws.com/static/website/redev_images/newBonusConfettiImg.svg" alt="Confetti" class="newSignupOffConfettiThirtyImg">
                            </div>
                        </div>
                        <button type="button" class="btn newSignUpOfferBtn p-0">NEW SIGN UP OFFER</button>
                        <p class="newSignupTodayTxt mb-0">Sign up today and <span class="signUpTodayYellowTxt"> get £30 FREE</span></p>
                        <p class="newSignupNoCmpltFreeTxt">When you spend £5</p>
                        <button type="button" class="btn newOffChooseBonusBtn">CHOOSE BONUS</button>
                    </div>
                    <div class="newSignupOffCardBg">
                        <div class="newSignUpOffConfettiSec">
                            <img src="https://raffolux-static.s3.eu-west-2.amazonaws.com/static/website/redev_images/50free.svg" alt="" class="newSignUpOffFiftyPoundsImg">
                            <div class="newSignupOffConfetti">
                                <img src="https://raffolux-static.s3.eu-west-2.amazonaws.com/static/website/redev_images/newBonusConfettiImg.svg" alt="Confetti" class="newSignupOffConfettiThirtyImg">
                            </div>
                        </div>
                        <button type="button" class="btn newSignUpOfferBtn p-0">NEW SIGN UP OFFER</button>
                        <p class="newSignupTodayTxt mb-0">Sign up today and <span class="signUpTodayYellowTxt"> get £50 FREE</span></p>
                        <p class="newSignupNoCmpltFreeTxt">When you spend £10</p>
                        <button type="button" class="btn newOffChooseBonusBtn">CHOOSE BONUS</button>
                    </div>
                    <div class="newSignupOffCardBg">
                        <div class="newSignUpOffConfettiSec">
                            <img src="https://raffolux-static.s3.eu-west-2.amazonaws.com/static/website/redev_images/50off+1.svg" alt="" class="newSignupOff50PerOffImg">
                            <img src="https://raffolux-static.s3.eu-west-2.amazonaws.com/static/website/redev_images/50%25freeGiftImg.svg" alt="" class="newSignUpOffFivePoundsImg">
                            <div class="newSignupOffConfetti">
                                <img src="https://raffolux-static.s3.eu-west-2.amazonaws.com/static/website/redev_images/newBonusConfettiImg.svg" alt="Confetti" class="newSignupOffConfettiImg">
                            </div>
                        </div>
                        <button type="button" class="btn newSignUpOfferBtn p-0">NEW SIGN UP OFFER</button>
                        <p class="newSignupTodayTxt mb-0">Sign up today and <span class="signUpTodayYellowTxt"> get 50% OFF</span></p>
                        <p class="newSignupNoCmpltFreeTxt">Your first purchase!</p>
                        <button type="button" class="btn newOffChooseBonusBtn">CHOOSE BONUS</button>
                    </div>
                    <div class="newSignupOffCardBg">
                        <div class="newSignUpOffConfettiSec">
                            <img src="https://raffolux-static.s3.eu-west-2.amazonaws.com/static/website/redev_images/50poundsOffer.svg" alt="" class="newSignUpOffFiftyPoundsBonusImg">
                            <div class="newSignupOffConfetti">
                                <img src="https://raffolux-static.s3.eu-west-2.amazonaws.com/static/website/redev_images/newBonusConfettiImg.svg" alt="Confetti" class="newSignupOffBonusConfettiImg">
                            </div>
                        </div>
                        <button type="button" class="btn newSignUpOfferBtn p-0">NEW SIGN UP OFFER</button>
                        <p class="newSignupTodayTxt mb-0">Sign up today and <span class="signUpTodayYellowTxt"> get A BONUS UP TO £50!</span></p>
                        <p class="newSignupNoCmpltFreeTxt">Get your first purchase back as credit!</p>
                        <button type="button" class="btn newOffChooseBonusBtn">CHOOSE BONUS</button>
                    </div>
                </div>
                <div class="mb-newSignUpOfferCardsBg">
                    <div class="row mb-newOfferCardBg">
                        <div class="col-6 p-0">
                            <button type="button" class="btn mb-newHomeSignupOffBtn p-0">NEW SIGN UP OFFER</button>
                            <p class="mb-newHomeSignupTxt mb-0">Sign up today and <span class="mb-newHomeYellowTxt"> get £5 FREE </span></p>
                            <p class="mb-homeCompleteFreeTxt">No catch, completely free!</p>
                            <button type="button" class="btn mb-newHomeChooseBtn">CHOOSE BONUS</button>
                        </div>
                        <div class="col-6 p-0 d-flex justify-content-center align-items-center">
                            <img src="https://raffolux-static.s3.eu-west-2.amazonaws.com/static/website/redev_images/5poundsImg.svg" alt="" class="mb-homeFivePoundsImg">
                        </div>
                    </div>
                    <div class="row mb-newOfferCardBg">
                        <div class="col-6 p-0">
                            <button type="button" class="btn mb-newHomeSignupOffBtn p-0">NEW SIGN UP OFFER</button>
                            <p class="mb-newHomeSignupTxt mb-0">Sign up today and <span class="mb-newHomeYellowTxt"> get £30 FREE </span></p>
                            <p class="mb-homeCompleteFreeTxt">When you spend £5</p>
                            <button type="button" class="btn mb-newHomeChooseBtn">CHOOSE BONUS</button>
                        </div>
                        <div class="col-6 p-0 d-flex justify-content-center align-items-center">
                            <img src="https://raffolux-static.s3.eu-west-2.amazonaws.com/static/website/redev_images/30Free.svg" alt="" class="mb-homeThirtyPoundsImg">
                        </div>
                    </div>
                    <div class="row mb-newOfferCardBg">
                        <div class="col-6 p-0">
                            <button type="button" class="btn mb-newHomeSignupOffBtn p-0">NEW SIGN UP OFFER</button>
                            <p class="mb-newHomeSignupTxt mb-0">Sign up today and <span class="mb-newHomeYellowTxt"> get £50 FREE </span></p>
                            <p class="mb-homeCompleteFreeTxt">When you spend £10</p>
                            <button type="button" class="btn mb-newHomeChooseBtn">CHOOSE BONUS</button>
                        </div>
                        <div class="col-6 p-0 d-flex justify-content-center align-items-center">
                            <img src="https://raffolux-static.s3.eu-west-2.amazonaws.com/static/website/redev_images/50free.svg" alt="" class="mb-homeFiftyPercentImg">
                        </div>
                    </div>
                    <div class="row mb-newOfferCardBg">
                        <div class="col-6 p-0">
                            <button type="button" class="btn mb-newHomeSignupOffBtn p-0">NEW SIGN UP OFFER</button>
                            <p class="mb-newHomeSignupTxt mb-0">Sign up today and <span class="mb-newHomeYellowTxt"> get 50% OFF </span></p>
                            <p class="mb-homeCompleteFreeTxt">Your first purchase</p>
                            <button type="button" class="btn mb-newHomeChooseBtn">CHOOSE BONUS</button>
                        </div>
                        <div class="col-6 p-0 d-flex justify-content-center align-items-center position-relative">
                            <img src="https://raffolux-static.s3.eu-west-2.amazonaws.com/static/website/redev_images/50off+1.svg" alt="" class="mb-newOff50PerOffImg">
                            <img src="https://raffolux-static.s3.eu-west-2.amazonaws.com/static/website/redev_images/50%25freeGiftImg.svg" alt="" class="mb-newOffFiftyPoundsImg">
                        </div>
                    </div>
                    <div class="row mb-newOfferCardBg">
                        <div class="col-6 p-0">
                            <button type="button" class="btn mb-newHomeSignupOffBtn p-0">NEW SIGN UP OFFER</button>
                            <p class="mb-newHomeSignupTxt mb-0">Sign up today and <span class="mb-newHomeYellowTxt"> get A BONUS UP TO £50! </span></p>
                            <p class="mb-homeCompleteFreeTxt">Get your first purchase back as credit!</p>
                            <button type="button" class="btn mb-newHomeChooseBtn">CHOOSE BONUS</button>
                        </div>
                        <div class="col-6 p-0 d-flex justify-content-center align-items-center">
                            <img src="https://raffolux-static.s3.eu-west-2.amazonaws.com/static/website/redev_images/50poundsOffer.svg" alt="" class="mb-homeFiftyPoundsImg">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
// import moment from "moment";
import {
    mapActions,
    mapGetters
} from "vuex";
import moment from 'moment-timezone';
import '@/assets/css/newSignUpOffers.css'
import loader from "../ReusableComponents/loader.vue";
import {
    urlLink,
    socialImgUrlLink
} from '@/s3bucket';
import {
    apiURL
} from "@/api/allApis"

export default {
    components: {
        loader: loader
    },

    data: () => ({
        featuredRaffles: [],
        urlLink: urlLink,
        socialImgUrlLink: socialImgUrlLink,
        timer: null,
        showPriewButton: false,
        isLoading: true,
        countdownTimers: {},
        countdownAbove1Day: {},
        CountdownBellow1Day: {},
        countdownAbove7Day: {},
        isSignUpBonus: false
    }),

    mounted() {
        this.featured_allRaffles_withoutLogin();
        this.$store.dispatch("getSignUpBonusData");
        this.SignUpBonusStatus()
    },

    computed: {
        ...mapGetters(["getSignUpBonusData"]),
    },

    methods: {

        ...mapActions(['fetchSignUpBonusStatus']),
        
        async SignUpBonusStatus() {
            await this.fetchSignUpBonusStatus();
            // console.log("this.getSignUpBonusData", this.getSignUpBonusData)
            this.isSignUpBonus = this.getSignUpBonusData
        },

        signUp_page() {
            this.$router.push("/signUp/");
        },
        startCountdown() {
            this.featuredRaffles.forEach((raf) => {
                const countDownDate = moment(raf.RaffleExpire);
                const countdownTimer = setInterval(() => {
                    const updatedNow = moment();
                    if (countDownDate.isBefore(updatedNow)) {
                        this.countdownTimers[raf.id] = "EXPIRED";
                        clearInterval(countdownTimer);
                        return;
                    }
                    const updatedDuration = moment.duration(
                        countDownDate.diff(updatedNow)
                    );
                    const newUpdatedays = updatedDuration.asDays();
                    const updatedDays = String(parseInt(newUpdatedays)).padStart(2, '0');
                    //  const updatedDays = updatedDuration.days();
                    const updatedHours = String(updatedDuration.hours()).padStart(2, '0');
                    const updatedMinutes = String(updatedDuration.minutes()).padStart(2, '0');
                    const updatedSeconds = String(updatedDuration.seconds()).padStart(2, '0');

                    if (updatedDays > 1) {
                        this.countdownTimers[raf.id] = `${updatedDays} days`;
                    } else if (updatedDays == 1) {
                        this.countdownTimers[raf.id] = `1 day`;
                    } else {
                        this.countdownTimers[
                            raf.id
                        ] = `${updatedHours}:${updatedMinutes}:${updatedSeconds}`;

                    }
                }, 1000);
            });
        },

        startCountdownNew() {
            this.featuredRaffles.forEach((raf) => {
                const countDownDateUTC = moment.utc(raf.RaffleExpire);
                const countDownDateUK = countDownDateUTC.tz('Europe/London');
                const countdownTimer = setInterval(() => {
                    const updatedNow = moment();
                    const updatedDuration = moment.duration(countDownDateUK.diff(updatedNow));
                    const newUpdatedays = updatedDuration.asDays();
                    const updatedDay = countDownDateUK.format("dddd");
                    const updatedDays = String(parseInt(newUpdatedays)).padStart(2, '0');
                    const updatedHours = String(updatedDuration.hours()).padStart(2, '0');
                    const updatedMinutes = String(updatedDuration.minutes()).padStart(2, '0');
                    const updatedSeconds = String(updatedDuration.seconds()).padStart(2, '0');

                    if (updatedDays >= 1 && updatedDays < 7) {
                        this.countdownAbove1Day[raf.id] = `Draw ${updatedDay} ${countDownDateUK.format("ha")}`;
                    } else if (updatedDays > 7 && raf.RetailPrice >= 10000) {
                        this.countdownAbove7Day[raf.id] = `JACKPOT DRAW`;
                    } else if (updatedDays < 1) {
                        this.CountdownBellow1Day[raf.id] = `${updatedHours} : ${updatedMinutes} : ${updatedSeconds}`;
                    }

                    if (updatedDuration <= 0) {
                        clearInterval(countdownTimer);
                    }
                }, 1000);
            });
        },

        call_button(item) {
            this.idArray = item;
            this.$router.push({
                name: 'App',
                params: {
                    category: this.idArray.CategoryName,
                    id: this.idArray.RaffleCode,
                    slug: this.idArray.slug
                }
            });
        },

        async featured_allRaffles_withoutLogin() {
            try {
                fetch(
                        apiURL.featuredRaffles, {
                            method: "POST",
                            headers: {
                                "Content-type": "application/json; charset=UTF-8",
                            },
                        }
                    )
                    .then((response) => response.json())
                    .then((data) => {
                        this.featuredRaffles = data;
                        this.isLoading = false;
                        this.startCountdown();
                        this.startCountdownNew()

                    });
            } catch (error) {
                this.isLoading = false;
            }
        },

    },
};
</script>

<style scoped>
.progress-bar {
    overflow: visible;
}
</style>
