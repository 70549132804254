<template>
<div v-if="isSignUpBonus" class="row mb-newHomeBonusSecBg m-0">
    <div class="col-6 pl-0 pt-3 pr-0">
        <button type="button" class="btn mb-newOffBtn">NEW SIGN UP OFFER</button>
        <div class="mb-newSignupPadding">
            <p class="mb-newSignupTodayTxt">Sign up today and <span class="mb-newGetTwentyTxt"> GET £20 FREE</span></p>
            <p class="newCompleteFreeTxt">When you spend £5</p>
            <button type="button" class="btn mb-newBonusSignupBtn" @click="signUp_page()">SIGN UP</button>
        </div>
    </div>
    <div class="col-6 p-0">
        <div class="mb-homeSignUpOffConfSec d-flex justify-content-center">
            <img src="https://raffolux-static.s3.eu-west-2.amazonaws.com/static/website/redev_images/new20freeImg.png" alt="" class="mb-TwentyPoundsImg">
        </div>

    </div>
</div>
</template>

<script>
import {
    mapActions,
    mapGetters
} from "vuex";

import {
    urlLink,
    socialImgUrlLink
} from '@/s3bucket';

export default {

    data: () => ({
        isSignUpBonus: false
    }),

    mounted() {
        this.$store.dispatch("getSignUpBonusData");
        this.SignUpBonusStatus()
    },

    computed: {
        ...mapGetters(["getSignUpBonusData"]),
    },

    methods: {

        ...mapActions(['fetchSignUpBonusStatus']),

        async SignUpBonusStatus() {
            await this.fetchSignUpBonusStatus();
            // console.log("this.getSignUpBonusData", this.getSignUpBonusData)
            this.isSignUpBonus = this.getSignUpBonusData
        },

        signUp_page() {
            this.$router.push("/signUp/");
        },

    }

}
</script>
