<template>
    <!-- toggle for light and dark mode -->
    <div class="toggleMargin">
        <input type="checkbox" class="checkbox" id="checkbox" :checked="isChecked" />
        <label for="checkbox" class="checkbox-label">
           
            <span class="ball"></span>
        </label>
    </div>
    </template>
    
    <script>
    export default {
        data: () => ({
            isChecked: true,
        }),
        mounted() {
            const checkboxContent = document.getElementById("checkbox");        
            checkboxContent.addEventListener("change", () => {            
                document.body.classList.toggle("dark");
                localStorage.setItem("isChecked", checkboxContent.checked);
            });
            this.isChecked = localStorage.getItem("isChecked") === "true";
            if (this.isChecked == true) {
                document.body.classList.add("dark");
            }
        },
        methods: {},
    };
    </script>