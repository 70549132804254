<template>
<div class="page-content-wrapper">
    <div class="row tab-contents align-items-center">
        <div class="col-md-7 d-md-flex d-sm-block align-items-center hdrTxt">
            <h2>LIVE RAFFLES</h2>
            <div class="d-flex d-md-flex justify-content-center">
                <span class="d-flex raffle-sec align-items-center">
                    <div class="active-dot"></div>
                    <h4>{{ this.notEndingSoon.length }} active raffles</h4>
                </span>
                <span class="d-flex raffle-sec align-items-center me-sm-2">
                    <div class="ending-dot"></div>
                    <h4>{{ this.endingSoon.length }} ending soon</h4>
                </span>
            </div>
        </div>
        <div class="col-md-5" v-if="this.tabComponentCheck == 1">
            <ul class="nav nav-pills justify-content-end" id="pills-tab" role="tablist">
                <li class="nav-item" role="presentation">
                    <button class="nav-link active" id="pills-all-tab" data-bs-toggle="pill" data-bs-target="#all" type="button" role="tab" aria-controls="pills-all" aria-selected="true" @click="filterRaffles('all')">
                        All
                    </button>
                </li>
                <li class="nav-item" role="presentation" v-if="this.instantwinTab == true">
                    <button class="nav-link tabsList" id="pills-all-tab" data-bs-toggle="pill" data-bs-target="#all" type="button" role="tab" aria-controls="pills-all" aria-selected="false" @click="filterRaffles(199)" style="width: max-content;">
                        Instant Win
                    </button>
                </li>
                <li class="nav-item" role="presentation" v-if="this.cashTab == true">
                    <button class="nav-link tabsList" id="pills-all-tab" data-bs-toggle="pill" data-bs-target="#all" type="button" role="tab" aria-controls="pills-all" aria-selected="false" @click="filterRaffles(35)">
                        Cash
                    </button>
                </li>
                <li class="nav-item" role="presentation" v-if="this.electrTab == true">
                    <button class="nav-link tabsList" id="pills-all-tab" data-bs-toggle="pill" data-bs-target="#all" type="button" role="tab" aria-controls="pills-all" aria-selected="false" @click="filterRaffles(6)">
                        Tech
                    </button>
                </li>
                <li class="nav-item" role="presentation" v-if="this.carTab == true">
                    <button class="nav-link tabsList" id="pills-all-tab" data-bs-toggle="pill" data-bs-target="#all" type="button" role="tab" aria-controls="pills-all" aria-selected="false" @click="filterRaffles(36)">
                        Car
                    </button>
                </li>
                <!-- <li class="nav-item" role="presentation" v-if="this.holidayTab == true">
                    <button class="nav-link tabsList" id="pills-all-tab" data-bs-toggle="pill" data-bs-target="#all" type="button" role="tab" aria-controls="pills-all" aria-selected="false" @click="filterRaffles(9)">
                        Holiday
                    </button>
                </li> -->
                <!-- <li class="nav-item" role="presentation" v-if="this.promoTab == true">
                    <button class="nav-link tabsList" id="pills-all-tab" data-bs-toggle="pill" data-bs-target="#all" type="button" role="tab" aria-controls="pills-all" aria-selected="false" @click="filterRaffles(100)">
                        Promotion
                    </button>
                </li> -->

                <li class="nav-item" role="presentation">
                    <button class="nav-link tabsList" id="pills-all-tab" data-bs-toggle="pill" data-bs-target="#all" type="button" role="tab" aria-controls="pills-all" aria-selected="false" @click="filterRaffles('others')">
                        Other
                    </button>
                </li>
            </ul>
        </div>
    </div>

    <div class="tab-content" id="pills-tabContent">
        <div class="tab-pane fade show active" id="all" role="tabpanel" aria-labelledby="pills-all-tab" tabindex="0">
            <ul class="p-0 homeTab_cards">
                <li class="tab-cards position-relative" v-for="raf in filteredRaffles" :key="raf.id" @click="call_raffale_page(raf)">
                    <img :src="urlLink + raf.MiniImageUrl" alt="" class="newRaffCardImg">

                    <!-- new timer bellow 1day -->
                    <div v-if="raf.is_timer_enabled == true && CountdownBellow1Day[raf.id]" class="new_timer_count">
                        <div class="d-flex justify-content-center">
                            <i class="fa-solid fa-timer newTimerClock"></i>
                            <h5 class="mb-0">
                                {{ CountdownBellow1Day[raf.id] }}
                            </h5>
                        </div>
                    </div>

                    <!-- new timer for btwn 24 hrns and 7 days-->
                    <div v-if="countdownAbove1Day[raf.id]" class="new_timer_countDraw">
                        <div class="d-flex justify-content-center">
                            <h5 class="mb-0">
                                {{ countdownAbove1Day[raf.id] }}
                            </h5>
                        </div>
                    </div>

                    <!-- new timer for more than 7 days and 10000 -->
                    <div v-if="countdownAbove7Day[raf.id]" class="new_timer_countDraw">
                        <div class="d-flex justify-content-center">
                            <h5 class="mb-0">
                                {{ countdownAbove7Day[raf.id] }}
                            </h5>
                        </div>
                    </div>

                    <div class="newRaffCardSecBg">
                        <div class="newRaffDtlsBg">
                            <div class="titleHeight">
                                <div class="homeEllipsis raffleCardTitle">{{ raf.Title }}</div>

                                <span v-if="raf.is_unlimited_raffle != true || raf.custom_raffle_id == null">
                                    <h3 v-if="isSpecialCategoryWinnerFinal && isSpecialCategory(raf.CategoryID_id)">
                                        <span v-if="raf.cash_alternative > 0 ">Cash alternative : £{{ raf.cash_alternative }}</span>
                                        <span v-else>
                                            Cash alternative : £{{ ((70 / 100) * raf.RetailPrice) % 1 === 0 ? ((70 / 100) * raf.RetailPrice).toFixed(0) : ((70 / 100) * raf.RetailPrice).toFixed(2) }}
                                        </span>
                                    </h3>
                                </span>

                            </div>
                            <span v-if="raf.is_launch_price == true" class="launch_price d-flex align-items-center justify-content-between">
                                <h4 class="me-0 ms-0 ">£{{ raf.discounted_entry_cost_gbp }}</h4>
                                <h5>£{{ raf.entry_cost_gbp }}</h5>
                                <div class="price_bg">LAUNCH PRICE</div>
                            </span>

                            <h4 v-else>£{{ raf.entry_cost_gbp }}</h4>
                            <div class="progressBarSpace position-relative">
                                <span v-if="raf.is_unlimited_raffle != true || raf.custom_raffle_id == null">
                                    <h6>{{ raf.percentage_sold }}% SOLD</h6>
                                    <div class="progress progress-square">
                                        <div class="progress-bar progress-square homeprogressMob position-absolute " role="progressbar" :style="{ width: raf.percentage_sold + '%' }" aria-valuenow="20" aria-valuemin="0" aria-valuemax="100">
                                            <div class="mob-homeProgressDot"></div>
                                        </div>

                                    </div>
                                </span>
                            </div>
                            <button type="button" class="btn newAllRaffEnterBtn" @click="call_raffale_page(raf)">Enter
                                now
                                <img src="https://raffolux-static.s3.eu-west-2.amazonaws.com/static/website/redev_images/raffoluxIcon.svg" alt="" class="newRaffIcon">
                            </button>
                        </div>
                        <button type="button" class="btn mobnewAllRaffEnterBtn" @click="call_raffale_page(raf)">Enter
                            now
                            <img src="https://raffolux-static.s3.eu-west-2.amazonaws.com/static/website/redev_images/raffoluxIcon.svg" alt="" class="newRaffIcon">
                        </button>
                    </div>

                  

                </li>
            </ul>
        </div>
    </div>
</div>

</template>

<script>
import {
    apiURL
} from "@/api/allApis"
import moment from 'moment-timezone';

import {
    urlLink,
    socialImgUrlLink
} from '@/s3bucket.js'
export default {
    data: () => ({
        allRaffles: [],
        // urlLink: "https://static.raffolux.com/static/website/",
        timer: null,
        filteredRaffles: [],
        countdownAbove1Day: {},
        CountdownBellow1Day: {},
        countdownAbove7Day: {},
        activeTab: "all",
        endingSoon: [],
        notEndingSoon: [],
        instantwinTab : false,
        cashTab: false,
        electrTab: false,
        carTab: false,
        holidayTab: false,
        promoTab: false,
        tabComponentCheck: 0,
        timerArray: [],
        urlLink: urlLink,
        socialImgUrlLink: socialImgUrlLink,
    }),
    created() {
        this.get_AllRaffleWithoutLogin();
    },

    computed: {

        isSpecialCategoryWinnerFinal() {
            const specialCategories = [
                "supersaturday",
                "christmas",
                "promotion",
                "fitness",
                "car",
                "electronic",
                "experience",
                "holiday",
                "indulgence",
                "watch",
                "collectable",
                "jewellery",
                "handbag",
                "shoe",
                "vehicle",
                "home",
            ];

            const categoryNames = this.filteredRaffles.map((category) =>
                category.CategoryName.toLowerCase()
            );

            return specialCategories.some((category) =>
                categoryNames.includes(category)
            );
        },
    },

    methods: {
        call_raffale_page(item) {
            this.idArray = item;
            this.$router.push({
                name: "App",
                params: {
                    category: this.idArray.CategoryName,
                    id: this.idArray.RaffleCode,
                    slug: this.idArray.slug
                },
            })
        },
      

        startCountdown() {
            this.filteredRaffles.forEach((raf) => {
                const countDownDateUTC = moment.utc(raf.RaffleExpire);
                const countDownDateUK = countDownDateUTC.tz('Europe/London');
                const countdownTimer = setInterval(() => {
                    const updatedNow = moment();
                    const updatedDuration = moment.duration(countDownDateUK.diff(updatedNow));
                    const newUpdatedays = updatedDuration.asDays();
                    const updatedDay = countDownDateUK.format("dddd");
                    const updatedDays = String(parseInt(newUpdatedays)).padStart(2, '0');
                    const updatedHours = String(updatedDuration.hours()).padStart(2, '0');
                    const updatedMinutes = String(updatedDuration.minutes()).padStart(2, '0');
                    const updatedSeconds = String(updatedDuration.seconds()).padStart(2, '0');

                    if (updatedDays >= 1 && updatedDays < 7) {
                        this.countdownAbove1Day[raf.id] = `Draw ${updatedDay} ${countDownDateUK.format("ha")}`;
                    } else if (updatedDays > 7 && raf.RetailPrice >= 10000) {
                        this.countdownAbove7Day[raf.id] = `Jackpot Draw`;
                    } else if (updatedDays < 1) {
                        this.CountdownBellow1Day[raf.id] = `${updatedHours} : ${updatedMinutes} : ${updatedSeconds}`;
                    }

                    if (updatedDuration <= 0) {
                        clearInterval(countdownTimer);
                    }
                }, 1000);
            });
        },

        async get_AllRaffleWithoutLogin() {
            fetch(apiURL.allRaffles, {
                    method: "POST",
                    headers: {
                        "Content-type": "application/json; charset=UTF-8",
                    },
                })
                .then((response) => response.json())
                .then((data) => {
                    this.allRaffles = data;

                    this.activeEndingRaffle();
                    this.checkTabs();
                    this.filteredRaffles = this.allRaffles;
                    this.tabComponentCheck = 1;
                    this.startCountdown();
                });
        },

        isSpecialCategory(categoryId) {
            const specialCategories = [
                "supersaturday",
                "christmas",
                "promotion",
                "fitness",
                "car",
                "electronic",
                "experience",
                "holiday",
                "indulgence",
                "watch",
                "collectable",
                "jewellery",
                "handbag",
                "shoe",
                "vehicle",
                "home",
            ];

            const category = this.filteredRaffles.find(
                (category) => category.CategoryID_id === categoryId
            );

            if (category) {
                return specialCategories.includes(category.CategoryName.toLowerCase());
            }
            return false;
        },

        checkTabs() {
            for (var i = 0; i < this.allRaffles.length; i++) {
                if (this.allRaffles[i].CategoryID_id == 35) {
                    this.cashTab = true;
                } else if (this.allRaffles[i].CategoryID_id == 6) {
                    this.electrTab = true;
                } else if (this.allRaffles[i].CategoryID_id == 36) {
                    this.carTab = true;
                } else if (this.allRaffles[i].CategoryID_id == 9) {
                    this.holidayTab = true;
                } else if (this.allRaffles[i].CategoryID_id == 100) {
                    this.promoTab = true;
                } else if (this.allRaffles[i].CategoryID_id == 199) {
                    this.instantwinTab = true;
                }
            }
        },
        activeEndingRaffle() {
            this.allRaffles.forEach((raffle) => {
                const raffleEnd = new Date(raffle.RaffleExpire);
                const now = new Date();
                const timeDiff = raffleEnd.getTime() - now.getTime();
                const hoursDiff = Math.ceil(timeDiff / (1000 * 3600));
                if (hoursDiff > 0 && hoursDiff < 24) {
                    this.endingSoon.push(raffle);
                } else if (hoursDiff > 24) {
                    this.notEndingSoon.push(raffle);
                }
            });
        },
        filterRaffles(val) {
            this.activeTab = val;
            if (this.activeTab === "all") {
                this.filteredRaffles = this.allRaffles;
            } else if (this.activeTab === 35) {
                this.filteredRaffles = this.allRaffles.filter(
                    (raffle) => raffle.CategoryID_id === 35
                );
            } else if (this.activeTab === 6) {
                this.filteredRaffles = this.allRaffles.filter(
                    (raffle) => raffle.CategoryID_id === 6
                );
            } else if (this.activeTab === 9) {
                this.filteredRaffles = this.allRaffles.filter(
                    (raffle) => raffle.CategoryID_id === 9
                );
            } else if (this.activeTab === 36) {
                this.filteredRaffles = this.allRaffles.filter(
                    (raffle) => raffle.CategoryID_id === 36
                );
            } else if (this.activeTab === 133) {
                this.filteredRaffles = this.allRaffles.filter(
                    (raffle) => raffle.CategoryID_id === 133
                );
            } else if (this.activeTab === 100) {
                this.filteredRaffles = this.allRaffles.filter(
                    (raffle) => raffle.CategoryID_id === 100
                );
            } else if (this.activeTab === 199) {
                this.filteredRaffles = this.allRaffles.filter(
                    (raffle) => raffle.CategoryID_id === 199
                );
            } else if (this.activeTab === "others") {
                const excludedCategoryIDs = [36, 35, 6, 133, 199];
                this.filteredRaffles = this.allRaffles.filter(
                    (raffle) => !excludedCategoryIDs.includes(raffle.CategoryID_id)
                );
            } else {
                this.filteredRaffles = this.allRaffles;
            }
        },
    },
};
</script>

<style scoped>
.image-container {
    width: 100%;
    max-width: 400px;
}

.aspect-ratio-box {
    position: relative;
    width: 100%;
    padding-bottom: calc(756 / 641 * 100%);
    /* Calculate aspect ratio (height/width) */
    /* For a 641:756 aspect ratio (as provided) */
}

.tab-cards {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    /* Use "contain" to maintain aspect ratio without cropping */
}
</style>
