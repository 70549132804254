<template>
<div>
    <!-- without login -->
    <div class="overflow-y-hidden mob-xScroll">
        <div class="row align-items-center navbar-desk" id="navbar-sec">
            <div class="col-md-6 p-0">
                <a class="cursor-pointer" @click="call_withoutHome()">
                    <img :src="s3Url + '/raffolux-slogan-yellow_navBar.png'" alt="" class="logo-img" />
                </a>
                <a @click="call_withoutHome()" class="allCompetitionsMargin">
                    <h5 class="competion-link">All competitions</h5>
                </a>
                <a @click="charity" class="winnersLinkMargin">
                    <h5 class="winner-link">Charity</h5>
                </a>
            </div>

            <div class="col-md-6 text-end">
                <webNewLoginSignUp></webNewLoginSignUp>

            </div>
        </div>

        <div class="row align-items-center navbar-mb" id="navbar-sec">
            <div class="col-4">
                <button type="button" class="btn signUpMobile-btn" @click=" isSignInPage ? signIn_page() : signUp_page()">
                    <div v-if="isSignInPage">SIGN IN</div>
                    <div v-else>SIGN UP</div>
                </button>

            </div>
            <div class="col-4 text-center">
                <a class="cursor-pointer" @click="call_withoutHome()">
                    <img :src="s3Url + '/raffolux-slogan-yellow_navBar.png'" alt="" class="logo-img" />
                </a>
            </div>
            <div class="col-4 text-end">
                <div class="position-relative d-inline">
                    <img src="https://static.raffolux.com/static/website/redev_images/cartIcon.svg" alt="" @click="cart_withoutlogin" class="new_home_cart_icon">

                    <span v-if="guestCartCount && guestCartCount > 0" class="new_cart-badge">{{ guestCartCount }}</span>
                </div>

                <img :src="`${s3Url}/open-menu.svg`" alt="" class="btn mb-offCanvasImg" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasWithoutLogin" aria-controls="offcanvasWithoutLogin" />
            </div>

        </div>
    </div>
    <!-- without login ends -->

    <!-- withoutlogin sidebar starts-->
    <div class="offcanvas offcanvas-start offcanvasWithoutLogin" tabindex="-1" id="offcanvasWithoutLogin" aria-labelledby="offcanvasWithOutLoginLabel">
        <div class="offcanvas-header offcanvasHeaderBg d-block">
            <div class="d-flex justify-content-end">
                <i class="fa-solid fa-xmark canvasClose" data-bs-dismiss="offcanvas" aria-label="Close"></i>
            </div>

        </div>
        <div class="offcanvas-body menuBarWithoutLoginBg">
            <p>menu</p>
            <div class="withoutLoginMenuCard" @click="homePage">
                <span class="d-flex align-items-center position-relative">
                    <i class="fa-light fa-house withoutLoginTrophyIcon"></i>
                    <h4 class="withoutLoginMenuCardTxt">Home</h4>
                </span>
            </div>
            <div class="withoutLoginMenuCard mb-storeMenuBtnHide" @click="winnersPage">
                <span class="d-flex align-items-center position-relative">
                    <i class="fa-regular fa-trophy withoutLoginTrophyIcon"></i>
                    <h4 class="withoutLoginMenuCardTxt">Winners</h4>
                </span>
            </div>
            <div class="withoutLoginMenuCard web-storeMenuBtnHide" @click="winnersPage">
                <span class="d-flex align-items-center position-relative">
                    <i class="fa-regular fa-trophy withoutLoginTrophyIcon"></i>
                    <h4 class="withoutLoginMenuCardTxt">Winners</h4>
                </span>
            </div>

            <div class="withoutLoginMenuCard" @click="charity">
                <span class="d-flex align-items-center position-relative">
                    <i class="fa-light fa-ribbon withoutLoginRocketIcon"></i>
                    <h4 class="withoutLoginMenuCardTxt">Charity</h4>
                </span>
            </div>
            <div class="withoutLoginMenuCard">
                <span class="d-flex align-items-center position-relative">
                    <i class="fa-regular fa-moon withoutLoginMoonIcon"></i>
                    <h4 class="withoutLoginMenuCardTxt">Light / Dark</h4>
                    <div class="withoutLogintoggleBtn">
                        <div class="toggleMargin">

                            <toggle></toggle>
                        </div>
                    </div>
                </span>
            </div>
            <div class="withoutLoginMenuCard" @click="help_support">
                <span class="d-flex align-items-center position-relative">
                    <i class="fa-regular fa-circle-question withoutLoginRocketIcon"></i>
                    <h4 class="withoutLoginMenuCardTxt">Help & FAQs</h4>
                </span>
            </div>
            <div class="text-center termsSec_links">
                <a @click="terms_conditions" class="menuSecCursor">Terms</a>
                |
                <a @click="privacy_policy" class="menuSecCursor">Privacy </a>
                |
                <a @click="help_support" class="menuSecCursor">Help </a>
                |
                <a @click="responsiblePlay" class="menuSecCursor">Responsible Play </a>
            </div>
            <div class="withoutLoginMediaBg">
                <a href="https://www.facebook.com/raffolux/" class="withoutLoginMediaLinks">
                    <img :src="s3Url + '/facebookLightTheme.svg'" class="withoutLoginmediaLight" alt="">
                    <img :src="s3Url + '/facebookDarkTheme.svg'" class="withoutLoginmediaDark" alt="">
                </a>
                <a href="https://twitter.com/i/flow/login?redirect_after_login=%2Fraffolux%2F" class="withoutLoginMediaLinks">
                    <img :src="s3Url + '/twitterLightTheme.svg'" class="withoutLoginmediaLight" alt="">
                    <img :src="s3Url + '/twitterDarkTheme.svg'" class="withoutLoginmediaDark" alt="">
                </a>
                <a href="https://www.instagram.com/raffolux/" class="withoutLoginMediaLinks">
                    <img :src="s3Url + '/instagramLightTheme.svg'" class="withoutLoginmediaLight" alt="">
                    <img :src="s3Url + '/instagramDarkTheme.svg'" class="withoutLoginmediaDark" alt="">
                </a>
                <a href="https://www.linkedin.com/uas/login?session_redirect=https%3A%2F%2Fwww.linkedin.com%2Fcompany%2Fraffolux%2Fabout%2F" class="withoutLoginMediaLinks">
                    <img :src="s3Url + '/linkedInLightTheme.svg'" class="withoutLoginmediaLight" alt="">
                    <img :src="s3Url + '/linkedInDarkTheme.svg'" class="withoutLoginmediaDark" alt="">
                </a>
            </div>
            <div class="d-flex justify-content-center">

            </div>
            <hr class="withoutLoginMenuHr">
            <div class="d-flex justify-content-center">

            </div>

        </div>
    </div>
    <!-- without login sidebar ends-->
</div>
</template>

<script>
import webNewLoginSignUp from '@/views/webNewLoginSignUp.vue';
import toggle from "../ReusableComponents/toggle.vue";
import "@/assets/css/global.css";
import "@/assets/css/sideBar.css";
import {
    socialImgUrlLink
} from '@/s3bucket.js';
import {
    mapGetters
} from 'vuex';
export default {

    components: {

        webNewLoginSignUp,
        toggle
    },
    data: () => ({
        s3Url: socialImgUrlLink + "redev_images",
        cart_details: [],
        isSignInPage: false
    }),

    created() {

        var guest_cart = JSON.parse(sessionStorage.getItem('guest_cartDetails'));
        this.cart_details = guest_cart;

        let pathNameurl = window.location.href;
        if (
            pathNameurl.includes("/signUp") ||
            pathNameurl.includes("/moreDetails") ||
            pathNameurl.includes("/verifyAccount") ||
            pathNameurl.includes("/activatedBonus")
        ) {
            this.isSignInPage = true
        } else {
            this.isSignInPage = false
        }

    },

    computed: {

        ...mapGetters(['guestCartCount']),

        guestCartCount() {
            return this.$store.getters.guestCartCount;
        },
    },

    methods: {
        signIn_page() {
            this.$router.push("/signIn/");
        },
        signUp_page() {
            dataLayer.push({
                'event': 'sign_up_accessed'
            });
            this.$router.push("/signUp/");
        },
        charity() {
            setTimeout(() => {
                $(".canvasClose").click();
                this.$router.push({
                    name: 'charityWithoutLogin'
                });
            }, 1000);
        },

        help_support() {
            $(".canvasClose").click();
            this.$router.push("/help/");
        },

        terms_conditions() {
            $(".canvasClose").click();
            this.$router.push("/tcs/");
        },

        privacy_policy() {
            $(".canvasClose").click();
            this.$router.push("/privacy/");
        },

        responsiblePlay() {
            $(".canvasClose").click();
            this.$router.push("/play/");
        },
        winnersPage() {
            $(".canvasClose").click();
            this.$router.push({
                name: 'winnersWithoutLogin'
            });

        },

        homePage() {
            $(".canvasClose").click();
            this.$router.push({
                name: "home"
            })

        },
        call_withoutHome() {
            this.$router.push({
                name: "home"
            })

            sessionStorage.removeItem('newReferredMobileUser');
        },
        cart_withoutlogin() {
            this.$router.push({ name: 'cartWithoutLogin' });
        }

    }
};
</script>
