<template>
<div class="slider">
    <div class="slide-track-1">
        <div class="slide" v-for="charity in allCharitiesData" :key="charity">
            <img :src="urlLink + charity.CharityLogoImgUrl" alt="">
        </div>
    </div>
</div>
</template>

<script>
// import {
//     Carousel,
//     Slide
// } from 'vue3-carousel'
import 'vue3-carousel/dist/carousel.css'
import {
    apiURL
} from "@/api/allApis";
import {
    urlLink
} from '@/s3bucket';

export default {

    components: {
        // Carousel,
        // Slide,
    },

    data: () => ({
        urlLink: urlLink,
        allCharitiesData: [],
        // model: null,
        // settings: {
        //     itemsToShow: 1,
        // },
        // numSlides: 0,
    }),

    created() {
        this.all_charities();
    },
    watch: {
        allCharitiesData: {
            handler() {
                this.$nextTick(() => {                    
                    this.duplicateImages();
                });
            },
            immediate: true,
        },
    },
    methods: {
        async all_charities() {
            await fetch(
                    apiURL.allCharitiesData, {
                        method: "POST",
                        headers: {
                            "Content-type": "application/json; charset=UTF-8",
                        },
                    }
                )
                .then((response) => response.json())
                .then((data) => {
                    this.allCharitiesData = data;
                    // console.log('this.allCharitiesData',this.allCharitiesData);
                });
        },

        duplicateImages() {
            var slideTrack = document.querySelector('.slide-track-1');
            var slides = slideTrack.querySelectorAll('.slide');
            const totalWidth = Array.from(slides).reduce((acc, slide) => {
                acc += slide.offsetWidth;
                return acc;
            }, 0);
            slideTrack.style.width = `${totalWidth * 10}px`;
            for (var i = 0; i < 4; i++) {
                for (var j = 0; j < slides.length; j++) {
                    var slideClone = slides[j].cloneNode(true);
                    slideTrack.appendChild(slideClone);
                }
            }
        }

    }
}
</script>

<style scoped>
/* .charity-carouselBg section {
    display: block;
    background: rgba(255, 255, 255, 0.202);
    height: 72px;
    padding: 11px 0px 13px;

}

.carousel__slide {
    width: 86% !important;
}

@media (min-width: 1440px) {
    .carousel__slide {
        width: 80% !important;
    }

}

@media (min-width: 768px) {

    .pb-md-4,
    .py-md-4 {
        padding-bottom: 1.5rem !important;
    }
}

.pt-0 {
    padding-top: 0px;
}

.carousel {
    display: flex;    
} */

.slider {
    background: rgba(255, 255, 255, 0.50);
    height: 54pt;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
    cursor: default;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    touch-action: none;
}

.slider .slide-track-1 {
    animation: scroll-l 30s linear infinite;
    display: flex;
    width: 200%;
    white-space: nowrap;
}

.slider .slide {
    height: 54pt;
    width: 84px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    flex: 0 0 auto;
}

.slider .slide img {
    width: 100px;
    /* padding: 1pc; */
    padding: 11px 16px 13px !important;
    vertical-align: middle;
    /* margin: 0 17px; */
    display: inline-block;
    max-width: 100%;
    height: auto;
}

@keyframes scroll-l {
    0% {
        transform: translateX(0);
    }

    100% {
        /* transform: translateX(calc(-84px * 18)); */
        transform: translateX(calc(-84px * 17));
    }
}
</style>
