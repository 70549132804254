<template>
<div>
    <button type="button" class="btn login-btn" id="webSignInBtn" data-bs-toggle="modal" data-bs-target="#newSignInModal">
        Sign In
    </button>
    <button type="button" class="btn signUp-btn" id="webSignUpBtn" data-bs-toggle="modal" data-bs-target="#newBonusSignupModal">
        Sign Up
    </button>

    <div class="position-relative d-inline">
        <img src="https://static.raffolux.com/static/website/redev_images/cartIcon.svg" alt="" @click="cart_withoutlogin" class="new_home_cart_icon">
        <span v-if="guestCartCount && guestCartCount > 0" class="new_cart-badge">{{ guestCartCount }}</span>
    </div>

    <img alt="" src="https://static.raffolux.com/static/website/redev_images/open-menu.svg" class="openMenuImg" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasWithoutLogin" aria-controls="offcanvasWithoutLogin">

    <!--new signin Modal -->
    <div class="modal modalBackdropCustom fade" id="newSignInModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered signUpModalStyles">
            <div class="modal-content signUpModalBg">
                <div class="modal-body p-0">
                    <div class="d-flex justify-content-end">
                        <i class="fa-solid fa-xmark signUpCloseMark" data-bs-dismiss="modal" @click="clear_fields"></i>
                    </div>

                    <v-snackbar v-model="snackbar" :timeout="timeout" :color="color" :top="true">
                        <div style="font-size:16px" class="text-center">
                            {{ message }}
                        </div>
                    </v-snackbar>
                    <div class="tab-pane show active" id="logIn-view" role="tabpanel" aria-labelledby="logIn-tab" tabindex="0">
                        <div class="login-sec">
                            <div class="text-center">
                                <div class="signInNewHeading">Sign in</div>
                            </div>
                            <div class="text-center mb-3 errorMsgStyles" v-if="ExistemailError" :style="{ color: 'red' }">
                                {{ ExistemailError }}
                            </div>

                            <div class="signUpBgPadding">
                                <div class="mb-0">
                                    <input type="email" class="form-control signUpEmailInputFiled" placeholder="your email" id="SignInEmail" aria-describedby="emailHelp" v-model="email" required @input="checklogin_data" />
                                    <div id="EmailError" class="text-left mb-1 errorMsgStyles" v-if="emailError" :style="{ color: 'red' }">
                                        {{ emailError }}
                                    </div>
                                </div>
                                <div class="mb-0">
                                    <input type="password" class="form-control signUpEmailInputFiled mb-0" placeholder="password" id="SignInPswd" aria-describedby="emailHelp" v-model="password" required @input="check_password" />
                                    <div id="PasswordError" class="text-left errorMsgStyles" v-if="passwordError" :style="{ color: 'red' }">
                                        {{ passwordError }}
                                    </div>
                                </div>
                                <p class="newsignInForgotPswdTxt mb-0" data-bs-toggle="modal" data-bs-target="#forgotPaswdModal">
                                    Forgot password?
                                </p>
                                <button type="button" class="btn newSignInBtn" :disabled="isLoading" @click="validate">
                                    {{ isLoading ? "Sign in " : "Sign in " }}
                                </button>
                                <p id="TotalError" class="text-center errorMsgStyles" v-if="error" :style="{ color: 'red' }">
                                    {{ error }}
                                </p>
                                <h5 class="social_hdrTxt">Sign in via social account</h5>
                                <p class="signUpOptionsBg" @click="login_withgoogle">
                                    <img src="https://static.raffolux.com/static/website/redev_images/signUpGoogleImg.svg" class="SignUpSocialMediaImgs" alt="" />
                                    Sign in with Google
                                </p>
                                <p class="signUpOptionsBg" @click="login_withfacebook">
                                    <img src="https://static.raffolux.com/static/website/redev_images/signupfacebookimg.svg" class="SignUpSocialMediaImgs" alt="" />
                                    Sign in with Facebook
                                </p>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>

    <!-- new signup modal -->
    <div class="modal fade modalBackdropCustom" id="newBonusSignupModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog newBonusSignupModal">
            <div class="modal-content newBonusSignupModalBg">
                <div class="modal-body p-0">
                    <div class="d-flex justify-content-end">
                        <i class="fa-regular fa-xmark signUpCloseMark newSignupClose" data-bs-dismiss="modal" @click="clear_fields()"></i>
                    </div>
                    <div v-if="isSignUpBonus" class="newFivePoundsSecBg p-0">
                        <div class="newHomeSignUpOffConfSec d-flex justify-content-center align-items-center">
                            <img src="https://raffolux-static.s3.eu-west-2.amazonaws.com/static/website/redev_images/new20freeImg.png" alt="" class="newSignUpTwentyPoundsImg">
                        </div>
                    </div>
                    <h2 class="signUpHeadingTxt newSignupBonusHeading" v-if="isSignUpBonus">
                        Create your Raffolux account and claim your bonus!
                    </h2>
                    <h2 class="signUpHeadingTxt newSignupBonusHeading" v-else>
                        Create your Raffolux account and join the action!
                    </h2>
                    <div class="signUpBgPadding">
                        <div class="mb-0">
                            <input type="email" class="form-control signUpEmailInputFiled" placeholder="your email" id="SignUpEmail" aria-describedby="emailHelp" v-model.trim="emailAddr" :class="{ 'is-invalid': emailError, 'homeErrorBg': emailCheckError }" @input="new_user_email_clear" />
                            <div id="EmailError1" v-if="emailError" class="invalid-feedback text-left mb-2">
                                {{ emailError }}
                            </div>
                        </div>
                        <button type="button" :class="{ 'is-invalid': emailCheckError }" class="btn SignUpGetStartBtn newBonusSignupGetStartBtn" @click="get_started()" :disabled="!isEmailValid">
                            Get started
                        </button>
                        <div id="checkEmail" v-if="emailCheckError" class="invalid-feedback text-center mb-4 homeEmailErrorTxt">
                            {{ emailCheckError }}
                        </div>
                        <div class="position-relative">
                            <p class="signUpOrTxt">or</p>
                            <hr class="signUpPageHr" />
                        </div>
                        <p class="signUpOptionsBg newBonusSignupOptions" @click="signup_with_google">
                            <img src="https://static.raffolux.com/static/website/redev_images/signUpGoogleImg.svg" class="SignUpSocialMediaImgs" alt="" />
                            Sign up with Google
                        </p>
                        <p class="signUpOptionsBg newBonusSignupOptions" @click="signup_with_facebook">
                            <img src="https://static.raffolux.com/static/website/redev_images/signupfacebookimg.svg" class="SignUpSocialMediaImgs" alt="" />
                            Sign up with Facebook
                        </p>
                        <!-- <p class="signUpOptionsBg newBonusSignupOptions mb-0">
                            <i class="fa-brands fa-apple newSignupAppleImg"></i>
                            Sign up with Apple
                        </p> -->
                        <p class="signUpAlreadyAcntTxt newSignupAlreadyTxt mb-0">
                            Already have an account?
                            <span class="textYellow" data-bs-toggle="modal" data-bs-target="#newSignInModal">Sign in
                            </span>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Forgot password  Modal -->
    <div class="modal fade modalBackdropCustom" id="forgotPaswdModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered forgotPswdStyles">
            <div class="modal-content forgotPswdBg">
                <div class="modal-body p-0">
                    <div class="d-flex justify-content-end" @click="clear_fieldsfrgt()">
                        <i class="fa-solid fa-xmark signUpCloseMark mb-0" data-bs-dismiss="modal"></i>
                    </div>
                    <div class="d-flex justify-content-start" @click="clear_fieldsfrgt()">
                        <i class="fa-solid fa-chevron-left signUpChevron" data-bs-toggle="modal" data-bs-target="#newSignInModal"></i>
                    </div>

                    <h5>Forgot password</h5>
                    <p>Enter the email address associated with your Raffolux account</p>
                    <div class="mb-0">
                        <input type="email" class="form-control forgotEmailInputField" placeholder="enter your email address" id="forgotSignUpEmail" aria-describedby="emailHelp" v-model="enteredEmail" @input="frgtEmailCheck">

                        <div id="EmailError" class="forgotEmailMsg mb-2" v-if="frgtEmailError" style="color:red">
                            {{ frgtEmailError }}
                        </div>

                        <div id="enteredEmailError" v-if="enteredEmailError" style="color:red" class="forgotEmailMsgback mb-2">
                            {{ enteredEmailError }}
                        </div>
                    </div>

                    <button type="button" class="btn resetPswdBtn disable" v-if="!isFormFilled" :disabled="isFormFilled">Reset password</button>

                    <button type="button" class="btn resetPswdBtn" @click="send_link_to_email()" v-else> Reset password</button>
                </div>
            </div>
        </div>
    </div>

    <!--forgot password link Modal -->
    <div class="modal fade modalBackdropCustom" id="forgotPswdLinkModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered forgotPswdStyles">
            <div class="modal-content resetLinkBg">
                <div class="modal-body p-0">
                    <div class="d-flex justify-content-end" @click="clear_fieldsfrgt()">
                        <i class="fa-solid fa-xmark signUpCloseMark mb-0" data-bs-dismiss="modal"></i>
                    </div>
                    <div class="d-flex justify-content-start" @click="clear_fieldsfrgt()">
                        <i class="fa-solid fa-chevron-left signUpChevron" data-bs-toggle="modal" data-bs-target="#forgotPaswdModal"></i>
                    </div>
                    <h5 class="mb-0">Forgot password</h5>
                    <p>We have sent an email to {{ enteredEmail }} with a link to reset your password</p>
                </div>
            </div>
        </div>
    </div>

    <!-- need more details Modal -->
    <div class="modalBackdropCustom modal fade" id="getStartedModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog signUpModalStyles">
            <div class="modal-content moreDetailsModalBg">
                <div class="modal-body p-0">
                    <div class="d-flex justify-content-between align-items-center">
                        <i class="fa-solid fa-chevron-left signUpChevron " data-bs-toggle="modal" data-bs-target="#newBonusSignupModal" @click="clear_fields"></i>
                        <i class="fa-solid fa-xmark signUpCloseMark mb-0" data-bs-dismiss="modal" @click="clear_fields"></i>
                    </div>
                    <h2 class="signUpMoreDtlsTxt">We just need a few more details</h2>
                    <div class="signUpNameFieldsPadding">

                        <div class="mb-0">
                            <label for="firstName" class="form-label signUpNameLabelNames">First name</label>
                            <input type="text" class="form-control signUpNameInputFileds" placeholder="enter your first name" id="firstName" aria-describedby="emailHelp" v-model.trim="firstName" :class="{ 'is-invalid': firstNameError }" @input="first_name" />
                            <div id="FirstNameError" v-if="firstNameError" class="invalid-feedback text-left mb-2">
                                {{ firstNameError }}
                            </div>
                        </div>
                        <div class="mb-0">
                            <label for="lastName" class="form-label signUpNameLabelNames">Last name</label>
                            <input type="text" class="form-control signUpNameInputFileds mb-0" placeholder="enter your last name" id="lastName" aria-describedby="emailHelp" v-model.trim="lastName" :class="{ 'is-invalid': lastNameError }" @input="last_name" />
                            <div id="LastNameError" v-if="lastNameError" class="invalid-feedback text-left mb-2">
                                {{ lastNameError }}
                            </div>
                        </div>
                        <div class="mb-0">
                            <label for="signUpPhno3" class="form-label signUpNameLabelNames signUpNameNumberLabelNames">Phone Number</label>
                            <div class="SignUpPhSec">
                                <div class="dropdown">
                                    <button class="btn signUpPhBtn dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        <img v-if="selectedCountry" :src="selectedCountry.image" alt="Country Flag" class="country-flag" />
                                        <span v-if="selectedCountry">
                                            {{ selectedCountry.dial_code }}
                                        </span>
                                        <span v-else>
                                            <img src="https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GB.svg" alt="Default Flag" class="country-flag" />
                                            +44
                                        </span>
                                    </button>
                                    <ul class="dropdown-menu">
                                        <li>
                                            <input type="text" v-model="search" class="form-control" placeholder="Search country..." />
                                        </li>

                                        <li v-for="country in filteredCountryList" :key="country">
                                            <a class="dropdown-item" @click="
                            selectCountry(country);
                            clearSearch();
                          ">
                                                <img :src="country.image" alt="Country Flag" class="country-flag" />
                                                {{ country.name }} {{ country.dial_code }}
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <input class="form-control signUpPhInputFileds signUpNameInputFileds" id="signUpPhno3" aria-describedby="emailHelp" v-model="contactNum" :class="{ 'is-invalid': contactError }" @click="clear_data_number" @input="contact_num" />
                                <div id="contactNumber" v-if="contactError" class="invalid-feedback text-left">
                                    {{ contactError }}
                                </div>
                            </div>
                        </div>
                        <p class="signUpverificationTxt">
                            We use this number to verify your account and will never contact
                            you for marketing purposes unless opted in via marketing
                            preferences.
                        </p>
                        <div class="mb-0">
                            <label for="password" class="form-label signUpNameLabelNames">Please Enter a Password</label>
                            <input type="password" class="form-control signUpNameInputFileds mb-2" placeholder="Password" id="password" aria-describedby="emailHelp" v-model.trim="passwordNew" :class="{ 'is-invalid': passwordError }" @input="password_check" />
                            <p class="webPswdCharTxt">Password must be at least 6 characters long</p>
                            <div v-if="passwordError" class="invalid-feedback text-left mb-2">
                                {{ passwordError }}
                            </div>
                        </div>
                        <!-- <p class="signUpPswdTxt">
                            Password must have at least 6 characters
                        </p> -->
                        <!-- <div class="accordion signUpAccordBg" id="accordionExample">
                            <div class="accordion-item refCodeAccordion">
                                <h2 class="accordion-header">
                                    <button class="accordion-button collapsed p-0 refAccBtn" type="button" data-bs-toggle="collapse" data-bs-target="#refcollapse" aria-expanded="false" aria-controls="refcollapse">
                                        <label for="refcode" class="form-label signUpNameLabelNames mb-0">Referral Code</label>
                                    </button>
                                </h2>
                                <div id="refcollapse" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                    <div class="accordion-body p-0 ">
                                        <div class="mb-0">

                                            <input type="text" class="form-control signUpNameInputFileds mb-2" id="refcode" placeholder="enter your referral code (If Referred)" v-model.trim="refCode" />
                                        </div>
                                        <div id="referralIdError" v-if="referralIdError" :style="{ color: 'red' }" class="errorMsgStyles text-left">
                                            {{ referralIdError }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> -->

                        <div class="checkbox_inp">
                            <div class="mb-3 d-flex gap-4 align-items-start">
                                <input type="checkbox" class="chk_box" v-model="checkOtp" id="exampleCheck1" />
                                <label class="form-check-label signUpNameLabelNames text-left" for="exampleCheck1">I would like to receive exciting updates on raffles, my
                                    chosen charity, partner promotions, exclusive discounts and
                                    free tickets!</label>
                            </div>

                            <div class="mb-3 d-flex gap-4 align-items-start">
                                <input type="checkbox" class="" v-model="checkSMS" id="exampleCheck2" />
                                <label class="form-check-label signUpNameLabelNames text-left" for="exampleCheck2">I would like to receive exclusive offers and discounts via
                                    SMS</label>
                            </div>
                        </div>

                        <button type="button" class="btn signUpContinueBtn" :disabled="isLoading" @click="next_step_continue()">
                            {{ isLoading ? "Continue" : "Continue" }}
                        </button>
                        <p id="TotalErrorSignup" class="text-center mt-1 errorMsgStyles" v-if="error" :style="{ color: 'red' }">
                            {{ error }}
                        </p>
                        <p class="condition_text">By creating an account you agree that you are at least 18 years of age, a UK resident and have read, accept and agree to the <span> <a href="/tcs/" class="link_text text-decoration-none">Terms and Conditions </a></span> and <span> <a href="/privacy/" class="link_text text-decoration-none">Privacy Policy </a></span>.</p>

                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- account verification Modal -->
    <div class="modalBackdropCustom modal fade" id="pswdVerification" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog signUpModalStyles">
            <div class="modal-content acntVerificationModalBg">
                <div class="modal-body p-0">
                    <div class="d-flex justify-content-end align-items-center">
                        <!-- <i class="fa-solid fa-chevron-left signUpChevron " data-bs-toggle="modal" data-bs-target="#getStartedModal" @click="clear_fields"></i> -->
                        <i class="fa-solid fa-xmark signUpCloseMark mb-0" data-bs-dismiss="modal" @click="clear_fields_home()"></i>
                    </div>
                    <h2 v-if="isSignUpBonus" class="signUpMoreDtlsTxt signUpVerifyTxt">
                        Verify your account to claim your <span class="newAcntFreeBonusTxt">£20 FREE BONUS</span>
                    </h2>
                    <h2 v-else class="signUpMoreDtlsTxt signUpVerifyTxt">
                        Verify your account
                    </h2>
                    <div class="acntVerifyBgPAdding">
                        <div class="mb-0">
                            <label for="signUpPhno3" class="form-label me-2 signUpNameLabelNames signUpNameNumberLabelNames">Phone Number</label>
                            <div class="SignUpAcntPhSec">
                                <div class="dropdown">
                                    <span v-if="countryImage" class="btn signUpPhBtn dropdown-toggle d-flex justify-content-between align-items-center verifyNumDisable">
                                        <img :src="countryImage" alt="Default Flag" class="country-flag" />
                                        <p class="mb-0">{{ selectedCountryCode }}</p>
                                    </span>
                                </div>
                                <input type="text" class="form-control signUpPhInputFileds signUpNameInputFileds verifyNumDisable" id="signUpPhno" v-model="contactNum" aria-describedby="emailHelp" disabled />
                            </div>
                        </div>
                        <hr class="signUpHr" />
                        <p class="signUpCode">
                            Please enter the 6-digit code that was just sent to
                            <span class="signUpNum">
                                {{ selectedCountryCode }} {{ contactNum }}
                            </span>
                        </p>

                        <div class="signUpInput-styles">
                            <input type="text" inputmode="numeric" @focus="activeFieldIndex = index" :class="{ active: activeFieldIndex === index }" @keypress="restrictToNumbers($event)" v-for="(field, index) in fields" :key="index" v-model="field.value" maxlength="1" class="signUpInput-field-bg" :id="'inputField' + index" :ref="'inputField' + index" @input="moveToNextField(index)" oninput="this.value=this.value.replace(/[^0-9]/g,'');" />
                            <p v-if="error" class="error-msg text-left mt-1 mb-1 me-2">
                                <i class="fa-solid fa-circle-xmark crossMark-bg"></i>{{ error }}
                            </p>
                        </div>
                        <p class="signUpResendCode">
                            Didn’t receive a code?
                            <span class="signUpNum" @click="send_otp"> Resend code </span>
                        </p>

                        <button type="button" :class="[
                  'btn',
                  {
                    signUpverifyBtn: !isButtonEnabled,
                    versignUpverifyBtn: isButtonEnabled,
                  },
                ]" :disabled="isLoading || !isButtonEnabled" @click="validate_otp">
                            {{ isLoading ? "Verify" : "Verify" }}
                        </button>

                        <p class="signUpErrorMsg">
                            If you didnt receive a code please make sure your phone number
                            is correct and try again!
                        </p>
                        <button type="button" class="btn signUpSkipTxt p-0" data-bs-toggle="modal" data-bs-target="#skipStepModal">
                            skip for now
                        </button>
                    </div>
                </div>
            </div>

        </div>
    </div>

    <!-- skip this step Modal -->
    <div class="modalBackdropCustom modal fade" id="skipStepModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered signUpModalStyles">
            <div class="modal-content skipStepModalBg">
                <div class="modal-body p-0">
                    <div class="d-flex justify-content-end">
                        <i class="fa-solid fa-xmark signUpCloseMark" data-bs-dismiss="modal" @click="clear_fields_home()"></i>
                    </div>
                    <div class="text-center">
                        <i class="fa-regular fa-triangle-exclamation newSkipWarningIcon"></i>
                    </div>
                    <h3 v-if="isSignUpBonus" class="skipModalHeadingTxt">
                        Are you sure you don’t want your Free Bonus?
                    </h3>
                    <h3 v-else class="skipModalHeadingTxt">
                        Are you sure you don’t want to verify?
                    </h3>

                    <div class="skipModalBgPadding">
                        <p v-if="isSignUpBonus">
                            You can always verify and claim your free bonus later by going
                            to the Account Page and following the steps.
                        </p>
                        <p v-else>
                            You can always verify later by going
                            to the Account Page and following the steps.
                        </p>
                        <button type="button" class="btn signUpVerifyNow" @click="back_to_verification()">
                            Verify now
                        </button>
                        <p class="newSkipVerifyLink" @click="route_homepage()">Skip verification</p>
                        <!-- <button type="button" class="btn signUpVerifyNow signUpVerifyLater" @click="route_homepage()">
                            Verify later
                        </button> -->
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Modal -->
    <div class="modalBackdropCustom modal fade" id="signUpSuccessModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered signUpModalStyles">
            <div class="modal-content signUpSuccessBg">
                <div class="modal-body p-0">
                    <div class="d-flex justify-content-end">
                        <i class="fa-solid fa-xmark signUpCloseMark" data-bs-dismiss="modal" @click="clear_fields_home()"></i>
                    </div>
                    <img src="https://static.raffolux.com/static/website/redev_images/shieldimg.png" class="signUpShieldImg" alt="" />
                    <p class="signUpSuccessTxt">Success!</p>
                    <p class="signUpVerifiedTxt">
                        Your account has been verified successfully!
                    </p>
                    <!-- <button type="button" class="btn signUpChooseMyBonus" data-bs-toggle="modal" data-bs-target="#bonusActivatedModal">
                        CHOOSE MY FREE BONUS
                    </button> -->
                    <button type="button" class="btn signUpChooseMyBonus" @click="route_homepage()">
                        Get Started
                    </button>
                </div>
            </div>
        </div>
    </div>

    <!-- welcome to raffolux Modal -->
    <div class="modalBackdropCustom modal fade" id="freeBonusModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog freeBonusModalStyles">
            <div class="modal-content freeBonusModalBg">
                <div class="modal-body p-0">
                    <div class="d-flex justify-content-end">
                        <i class="fa-solid fa-xmark signUpCloseMark" data-bs-dismiss="modal" @click="clear_fields"></i>
                    </div>
                    <h3 class="signUpMoreDtlsTxt mb-0">Welcome to Raffolux, Joe!</h3>
                    <p class="freeBonusTxt">It’s time to pick your FREE BONUS!</p>
                    <ul class="freeBonusImgs">
                        <li class="freeBonusImgList">
                            <img src="https://static.raffolux.com/static/website/media23/raf2000A_mini.webp" class="freeBonusImg" alt="" data-bs-toggle="modal" data-bs-target="#bonusActivatedModal" />
                            <p class="freeBonusOptionTxt">Option 1</p>
                        </li>
                        <li class="freeBonusImgList">
                            <img src="https://static.raffolux.com/static/website/media23/raf2000A_mini.webp" class="freeBonusImg" alt="" />
                            <p class="freeBonusOptionTxt">Option 2</p>
                        </li>
                        <li class="freeBonusImgList">
                            <img src="https://static.raffolux.com/static/website/media23/raf2000A_mini.webp" class="freeBonusImg" alt="" />
                            <p class="freeBonusOptionTxt mb-0">Option 3</p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <!-- Bonus Activated Modal -->
    <div class="modalBackdropCustom modal fade" id="bonusActivatedModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog freeBonusModalStyles">
            <div class="modal-content bonusActivatedBg">
                <div class="modal-body p-0">
                    <div class="d-flex justify-content-end">
                        <i class="fa-solid fa-xmark signUpCloseMark" data-bs-dismiss="modal" @click="clear_fields_home()"></i>
                    </div>
                    <h2 class="signUpMoreDtlsTxt newBonusActivateTxt">BONUS ACTIVATED!</h2>
                    <!-- <p class="bonusActivatedTxt">
                        Nice work {{ firstName }}! Your exclusive sign up bonus has been activated and
                        you can now start playing Raffolux!
                    </p> -->
                    <div class="BonusActivatesCardDtlsBg">
                        <div class="d-flex justify-content-center">
                            <img src="https://raffolux-static.s3.eu-west-2.amazonaws.com/static/website/redev_images/new20freeImg.png" alt="" class="ActivateModalBonusImg">
                        </div>

                        <div class="featuresListBg">
                            <div class="BonusFeatures">
                                <p class="webWelcomeCode">£20 FREE CREDIT</p>
                                <p class="webOfferTxt">Make a purchase of £5 or more, and once the transaction is completed, you’ll receive £20 in credit deposited to your account. </p>
                                <!-- <p class="webDiscountTxt">(Discount will be automatically applied at checkout)</p> -->
                            </div>
                            <div class="d-flex justify-content-center">
                                <button type="button" class="btn StartPlayBtn" @click="route_homepage">
                                    To the raffles!
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <v-snackbar v-model="otpbar" :timeout="timeout" :color="color">
        <div class="text-center" style="font-size:16px">
            {{ otpmessage }}
        </div>
    </v-snackbar>

</div>
</template>

<script>
import {
    RaffoluxMixin
} from "@/mixins/pathName.js";

import axios from 'axios';
import {
    socialImgUrlLink
} from "@/s3bucket.js";
import countryList from "@/mixins/CountryList.json";
import {
    mapGetters,
    mapActions
} from 'vuex';
import "../assets/css/global.css";
import "../assets/css/signUp.css";
import {
    apiURL
} from "@/api/allApis";
import jwt_decode from "jwt-decode";
export default {
    emits: ["my-event", "contactNumFromLogin"],
    components: {

    },
    data() {
        return {
            enteredEmail: "",
            enteredEmailError: "",
            frgtEmailError: "",
            email: "",
            password: "",
            error: "",
            activeFieldIndex: null,
            socialImgUrlLink: socialImgUrlLink,
            newData: "",
            emailError: "",
            ExistemailError: "",
            emailCheckError: "",
            passwordError: "",
            snackbar: false,
            message: "Login Successful",
            timeout: 3000,
            color: "success",
            snackbarinstantPossitive: true,
            snackbarinstantNegative: true,
            isLoading: false,
            emailAddr: "",
            countryList,
            firstName: "",
            lastName: "",
            emailAddr: "",
            passwordNew: "",
            passwordConf: "",
            contactNum: "",
            emailError: null,
            firstNameError: null,
            contactError: null,
            lastNameError: null,
            passwordError: null,
            countryList,
            selectedCountry: null,
            search: "",
            responceData: {},
            checkSMS: false,
            checkOtp: false,
            isLoading: false,
            newReferredUserData: {},
            refCode: "",
            referralIdError: null,
            selectedCountryCode: "",
            error: null,
            check_api: "",
            cart: [],
            userSession: "",
            otpbar: false,
            color: "success",
            otpmessage: "Otp sent successfully",
            fieldValues: null,
            activeTab: "pills-signIn",
            emailCheck: "",
            userId: "",
            isSignUpBonus: false,
            storedUtmSource: "",
            storedUtmMedium: "",
            storedUtmCampaign: "",
            storedUtmContent: "",
            storedUtmTerm: "",
            cart_details: [],
            fields: [{
                    value: "",
                },
                {
                    value: "",
                },
                {
                    value: "",
                },
                {
                    value: "",
                },
                {
                    value: "",
                },
                {
                    value: "",
                },
            ],
        };
    },

    mixins: [RaffoluxMixin],

    created() {

        var guest_cart = JSON.parse(sessionStorage.getItem('guest_cartDetails'));
        this.cart_details = guest_cart;

        this.storedUtmSource = sessionStorage.getItem('utm_source');
        if (this.storedUtmSource === null || this.storedUtmSource === "undefined") {
            this.storedUtmSource = "";
        }
        this.storedUtmMedium = sessionStorage.getItem('utm_medium');
        if (this.storedUtmMedium === null || this.storedUtmMedium === "undefined") {
            this.storedUtmMedium = "";
        }
        this.storedUtmCampaign = sessionStorage.getItem('utm_campaign');
        if (this.storedUtmCampaign === null || this.storedUtmCampaign === "undefined") {
            this.storedUtmCampaign = "";
        }

        this.storedUtmContent = sessionStorage.getItem('utm_content');
        if (this.storedUtmContent === null || this.storedUtmContent === "undefined") {
            this.storedUtmContent = "";
        }

        this.storedUtmTerm = sessionStorage.getItem('utm_term');
        if (this.storedUtmTerm === null || this.storedUtmTerm === "undefined") {
            this.storedUtmTerm = "";
        }

        this.newReferredUserData = JSON.parse(
            sessionStorage.getItem("newReferredUser")
        );
        if (this.newReferredUserData) {
            this.emailAddr = this.newReferredUserData.userEmail;
            this.refCode = this.newReferredUserData.refCode;
        }
    },

    mounted() {

        this.$store.dispatch("getSignUpBonusData");
        this.SignUpBonusStatus()

        setTimeout(() => {
            let ExistEmail = JSON.parse(localStorage.getItem('ExistEmail'));

            if (ExistEmail) {
                this.ExistemailError = "Already you have an account, Please Sign In"
                this.email = ExistEmail;
            } else {
                localStorage.removeItem("ExistEmail");
            }
        }, 2000)

    },

    computed: {
        ...mapGetters(['guestCartCount']),
        ...mapGetters(["getSignUpBonusData"]),

        guestCartCount() {
            return this.$store.getters.guestCartCount;
        },

        isFormFilled() {
            return (
                this.enteredEmail !== "" &&
                this.frgtEmailError == ''
            );
        },

        isEmailValid() {
            return this.emailAddr && !this.emailError;
        },

        filteredCountryList() {
            return this.countryList.filter((country) => {
                const searchTerm = this.search.toLowerCase();
                const countryName = country.name ? country.name.toLowerCase() : "";
                const dialCode = country.dial_code ?
                    country.dial_code.toLowerCase() :
                    "";
                return (
                    countryName.includes(searchTerm) || dialCode.includes(searchTerm)
                );
            });
        },

        countryImage() {
            const matchedCountry = this.countryList.find(
                (country) => country.dial_code === this.selectedCountryCode
            );
            return matchedCountry ? matchedCountry.image : ""; // Return the image URL or an empty string if no match found
        },

        isButtonEnabled() {
            this.error = null;
            return this.fields.every((field) => field.value !== "");
        },

        ...mapGetters(['getcartCount']),

    },

    methods: {
        ...mapActions(['addToCart']),

        ...mapActions(['fetchCartItems']),

        ...mapActions(['fetchSignUpBonusStatus']),

        async SignUpBonusStatus() {
            await this.fetchSignUpBonusStatus();
            // console.log("this.getSignUpBonusData", this.getSignUpBonusData)
            this.isSignUpBonus = this.getSignUpBonusData
        },

        async fetchCartCount() {
            await this.fetchCartItems(this.userSession);

            this.cartCount = this.getcartCount

        },
        clear_fieldsfrgt() {
            this.enteredEmail = ""
            this.frgtEmailError = ""
        },

        new_user_email_clear() {
            this.isLoading = false;
            this.emailError = "";
            this.emailCheckError = ""
        },
        email_add() {
            // const re = /^[a-zA-Z0-9]+(?:\.[a-zA-Z0-9]+)*@[a-zA-Z0-9]+(?:\.[a-zA-Z0-9]+)*$/gm;
            const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.(com|org|net|edu|gov|int|mil|biz|info|coop|aero|museum|[a-zA-Z]{2,3})$/

            if (!this.emailAddr) {
                this.emailError = "Email address is required";
                this.emailCheckError = "";
                return false;
            } else if (!re.test(this.emailAddr)) {
                this.emailError = "Invalid email address";
                this.emailCheckError = "";
                return false;
            } else {
                this.emailError = "";
                this.emailCheckError = "";
            }
            return true;
        },
        password_check() {
            if (this.passwordNew.trim() === "") {
                this.passwordError = "Password is required";
                return false;
            } else {
                this.passwordError = "";
            }
            return true;
        },
        password_length_check() {
            if (this.passwordNew.trim().length < 6) {
                this.passwordError = "Password must have at least 6 characters long";
                return false;
            } else {
                this.passwordError = "";
            }
            return true;
        },
        contact_num() {
            const numericRegex = /^[0-9]+$/;

            if (!this.contactNum) {
                this.contactError = "Phone number is mandatory";
                return false;
            } else if (!numericRegex.test(this.contactNum)) {
                this.contactError = "Contact number should contain only numbers";
                return false;
            } else if (this.contactNum.toString().length < 10) {
                this.contactError = "Contact number must be 10 or 11 digits";
                return false;
            } else if (this.contactNum.toString().length > 11) {
                this.contactError = "Contact number must be 10 or 11 digits";
                return false;
            } else {
                this.contactError = "";
            }
            return true;
        },
        first_name() {
            if (this.firstName.trim() === "") {
                this.firstNameError = "First name is required";
                return false;
            } else if (!/^[a-zA-Z0-9]+$/.test(this.firstName.trim())) {
                this.firstNameError =
                    "First name should only contain alphanumeric characters";
                return false;
            } else if (this.firstName.trim().length > 40) {
                this.firstNameError = "First name must be less than 40 characters";
                return false;
            } else {
                this.firstNameError = "";
            }
            return true;
        },
        last_name() {
            if (this.lastName.trim() === "") {
                this.lastNameError = "Last name is required";
                return false;
            } else if (!/^[a-zA-Z0-9]+$/.test(this.lastName.trim())) {
                this.lastNameError =
                    "Last name should only contain alphanumeric characters";
                return false;
            } else if (this.lastName.trim().length > 30) {
                this.lastNameError = "Last name must be less than 30 characters";
                return false;
            } else {
                this.lastNameError = "";
            }
            return true;
        },
        selectCountry(country) {
            this.selectedCountry = country;
        },
        clearSearch() {
            this.search = "";
        },
        get_started() {
            this.isLoading = true;

            if (this.email_add()) {
                this.get_started_signUp()
                this.isLoading = false;

            }
        },
        async get_started_signUp() {
            this.isLoading = true;
            try {
                let signUpData = {
                    email: this.emailAddr,
                };
                await fetch(apiURL.checkEmail, {
                        method: "POST",
                        headers: {
                            "Content-type": "application/json; charset=UTF-8",
                        },
                        body: JSON.stringify(signUpData),
                    })
                    .then((response) => response.json())
                    .then((data) => {
                        this.emailCheck = data;
                        if (this.emailCheck.message == "New email") {
                            $("#getStartedModal").modal("show");
                            //    $("#pswdVerification").modal("show");
                            $("#newBonusSignupModal").modal("hide");
                            this.isLoading = false;
                            this.emailCheckError = "";
                        } else if (this.emailCheck.status === 400) {
                            this.emailCheckError = this.emailCheck.message;
                            this.isLoading = false;

                        } else if (this.emailCheck === "This Account is Blocked") {
                            this.emailCheckError = "This account is blocked"
                            this.isLoading = false;

                        } else {
                            this.emailCheckError = this.emailCheck;
                            this.isLoading = false;

                        }

                    })

            } catch (error) {

            }
        },
        back_to_verification() {
            $("#pswdVerification").modal("show");
            $("#skipStepModal").modal("hide");
        },
        restrictToNumbers(event) {
            // Allow only numeric input (0-9) in the input fields
            const charCode = event.keyCode || event.which;
            if (charCode < 48 || charCode > 57) {
                event.preventDefault();
            }
        },
        moveToNextField(index) {
            const maxLength = 1;
            if (
                this.fields[index].value.length >= maxLength &&
                index < this.fields.length - 1
            ) {
                this.$refs["inputField" + (index + 1)][0].focus();
            }

            if (this.fields[index].value.length >= maxLength) {
                document.getElementById("inputField" + index).classList.add("active");
            } else {
                document.getElementById("inputField" + index).classList.remove("active");
            }

            // Handle the last input field
            if (index === this.fields.length - 1) {
                this.activeFieldIndex = null; // Turn it grey
            }
        },
        clear_fields() {
            this.emailAddr = "";
            this.emailError = null;
            this.firstName = "";
            this.lastName = "";
            this.emailAddr = "";
            this.passwordNew = "";
            this.contactNum = "";
            this.firstNameError = null;
            this.contactError = null;
            this.lastNameError = null;
            this.passwordError = null;
            this.errpr = null;
            this.selectedCountry = null;
            this.checkSMS = false;
            this.checkOtp = false;
            this.error = "";
            this.email = "";
            this.password = "";
            this.PasswordError = "";
            this.refCode = "";
            this.emailCheckError = "";
            this.ExistemailError = ""
            sessionStorage.removeItem("newReferredUser");
            localStorage.removeItem("ExistEmail");
        },

        clear_fields_home() {
            this.emailAddr = "";
            this.emailError = null;
            this.firstName = "";
            this.lastName = "";
            this.emailAddr = "";
            this.passwordNew = "";
            this.contactNum = "";
            this.firstNameError = null;
            this.contactError = null;
            this.lastNameError = null;
            this.passwordError = null;
            this.errpr = null;
            this.selectedCountry = null;
            this.checkSMS = false;
            this.checkOtp = false;
            this.error = "";
            this.email = "";
            this.password = "";
            this.PasswordError = "";
            this.refCode = "";
            this.emailCheckError = "";
            this.ExistemailError = ""
            sessionStorage.removeItem("newReferredUser");
            localStorage.removeItem("ExistEmail");
            this.$router.push({
                name: "homeLogin"
            })
        },

        next_step_continue() {
            if (
                this.first_name() &&
                this.last_name() &&
                this.contact_num() &&
                this.password_check() &&
                this.password_length_check()
            ) {
                this.sign_up();
            }
        },

        async sign_up() {

            dataLayer.push({
                'event': 'sign_up_started_raffolux'
            });
            this.isLoading = true;
            this.error = "";
            this.referralIdError = "";
            try {
                this.selectedCountryCode = this.selectedCountry ?
                    this.selectedCountry.dial_code :
                    "+44";
                const contactNumber = this.selectedCountryCode + this.contactNum;
                let signUpData = {
                    email: this.emailAddr,
                    first_name: this.firstName,
                    last_name: this.lastName,
                    password: this.passwordNew,
                    confirmation_password: this.passwordNew,
                    contact_number: contactNumber,
                    raffle_updates_opt: this.checkOtp,
                    sms_updates_opt: this.checkSMS,
                    utmSource: this.storedUtmSource || "",
                    utmMedium: this.storedUtmMedium || "",
                    utmCampaign: this.storedUtmCampaign || "",
                    utmContent: this.storedUtmContent || "",
                    utmTerm: this.storedUtmTerm || ""

                };

                if (this.refCode) signUpData[`referralId`] = this.refCode;
                await fetch(apiURL.signUp, {
                        method: "POST",
                        headers: {
                            "Content-type": "application/json; charset=UTF-8",
                        },
                        body: JSON.stringify(signUpData),
                    })
                    .then((response) => response.json())
                    .then((data) => {
                        this.responceData = data;
                        this.isLoading = false;
                        if (this.responceData.status == 200) {
                            localStorage.setItem("user_session", JSON.stringify(this.responceData.data[0].jwt));
                            dataLayer.push({
                                'event': 'sign_up_completed',
                                'value': 'raffolux',
                            });
                            this.error = ""
                            const currentTimestamp = new Date().toISOString();
                            const _fbpValue = this.getCookieValue("_fbp");
                            const _fbcValue = this.getCookieValue("_fbc");

                            if (this.responceData.data[0].smsVerification == "true") {
                                $("#getStartedModal").modal("hide");
                                $("#pswdVerification").modal("show");

                                this.send_otp("0");

                            } else {
                                localStorage.removeItem("ExistEmail");

                                sessionStorage.removeItem("newReferredUser");
                                //const RaffleId = localStorage.getItem("RaffleId");
                                const addToCartCheckWithoutLogin = (sessionStorage.getItem("adding_toCart"));

                                if (!addToCartCheckWithoutLogin) {
                                    this.$router.push({
                                        name: "homeLogin"
                                    })
                                } else {
                                    const userSession = localStorage.getItem("user_session");
                                    this.$store.dispatch("fetchCartItems", userSession);
                                    this.call_addto_cart(userSession);
                                    sessionStorage.removeItem("adding_toCart");

                                }

                            }

                            if (this.isRaffoluxPath()) {
                                this.facebook_tracking_completeReg(currentTimestamp, _fbpValue, _fbcValue);
                            }
                        } else if (
                            this.responceData.message.message === "No refferral ID found"
                        ) {
                            this.referralIdError = "Enter valid refferral ID";
                        } else if (this.responceData === "Invalid Request") {
                            this.error = "Please enter the valid details";
                        } else if (this.responceData.status == 400) {
                            this.error = this.responceData.message
                        } else {
                            this.error = "Something went wrong. Please try again";
                        }
                    });
            } catch (error) {
                this.error = "Something went wrong. Please try again";
                // this.error = error;
                this.isLoading = false;
            }
        },
        async my_credit(userSession) {
            try {
                await fetch(
                        apiURL.myCreditData, {
                            method: "POST",
                            headers: {
                                "Content-type": "application/json; charset=UTF-8",
                                Authorization: JSON.parse(userSession),
                            },
                        }
                    )
                    .then((response) => response.json())
                    .then((data) => {
                        this.myCreditData = data;
                        localStorage.setItem("myCreditDatalocal", JSON.stringify(this.myCreditData));

                    });
            } catch (error) {
                this.error = error;
            }
        },

        getCookieValue(cookieName) {
            const name = cookieName + "=";
            const decodedCookie = decodeURIComponent(document.cookie);

            const cookieArray = decodedCookie.split(';');
            for (let i = 0; i < cookieArray.length; i++) {
                let cookie = cookieArray[i].trim();
                if (cookie.indexOf(name) === 0) {
                    return cookie.substring(name.length, cookie.length);
                }
            }

            return "";
        },

        facebook_tracking_completeReg(currentTimestamp, _fbpValue, _fbcValue) {

            this.check_userSession()
            try {
                fetch(
                        apiURL.facebookPixelCompleteRegistration, {
                            method: "POST",
                            headers: {
                                "Content-type": "application/json; charset=UTF-8",
                                "Authorization": JSON.parse(this.userSession),
                            },
                            body: JSON.stringify({
                                event_name: "complete_registration",
                                event_time: currentTimestamp,
                                user_data: {
                                    emails: this.emailAddr,
                                    first_name: this.firstName,
                                    last_name: this.lastName,
                                    external_id: this.userId,
                                    fbc: _fbcValue || "",
                                    fbp: _fbpValue || "",

                                },

                                event_source_url: "https://raffolux.com/",
                                action_source: "website",

                            }),
                        }
                    )
                    .then((response) => response.json())
                    .then((data) => {})
            } catch (error) {
                this.error = "";
            }
        },

        async signup_with_google() {
            dataLayer.push({
                'event': 'sign_up_started_google'
            });

            try {
                await fetch(apiURL.signUpgoogle, {
                        method: "POST",
                        headers: {
                            "Content-type": "application/json; charset=UTF-8",
                        },
                    })
                    .then((response) => response.json())
                    .then((data) => {

                        let googleSignupData = data.data;
                        window.location.href = googleSignupData.redirectUrl;
                        dataLayer.push({
                            'event': 'sign_up_completed',
                            'value': 'google',
                        });

                        if (this.isRaffoluxPath()) {

                            // Adalyser Web Tracking for Registration
                            // window.adalyserTracker("trackEvent", "lce2", {}, true);

                            fbq('track', 'CompleteRegistration', {});

                        }

                    });
            } catch (error) {
                this.error = "Google Sign up failed";
            }
        },
        async signup_with_facebook() {
            dataLayer.push({
                'event': 'sign_up_started_facebook'
            });

            try {
                await fetch(apiURL.signUpfacebook, {
                        method: "POST",
                        headers: {
                            "Content-type": "application/json; charset=UTF-8",
                        },
                    })
                    .then((response) => response.json())
                    .then((data) => {
                        let facebookSignupData = data.data;
                        window.location.href = facebookSignupData.redirectUrl;
                        dataLayer.push({
                            'event': 'sign_up_completed',
                            'value': 'facebook',
                        });

                        if (this.isRaffoluxPath()) {

                            // Adalyser Web Tracking for Registration
                            //  window.adalyserTracker("trackEvent", "lce2", {}, true);

                            fbq('track', 'CompleteRegistration', {});

                        }

                    });
            } catch (error) {
                this.error = "Facebook Sign up failed";
            }
        },
        async send_otp(val) {
            this.check_api = val
            this.check_userSession();

            try {
                let x = {
                    method: 'POST',
                    headers: {
                        'Content-type': 'application/json; charset=UTF-8',
                        Authorization: JSON.parse(this.userSession),
                    },
                    data: {},
                    url: apiURL.generateOtpSmsVerification,
                };
                const response = await axios(x);
                this.receivedSms = response.data.message;

                if (this.receivedSms) {
                    this.otpbar = true;

                    setTimeout(() => {
                        this.fields.forEach((field) => {
                            field.value = '';
                        });
                    }, 3000);
                }
                // console.log("newDataforResend OTP:", this.receivedSms);
            } catch (error) {

                if (this.check_api == "0") {
                    this.send_otp("1")
                } else {
                    if (error) {
                        this.error = error;
                    }
                }

            }
        },
        check_userSession() {

            this.userSession = localStorage.getItem("user_session");
            const decoded = jwt_decode(this.userSession);
            this.userId = decoded.user_id
        },

        async validate_otp() {
            this.error = null;
            this.display();
            this.check_userSession();
            try {
                await fetch(apiURL.verifySmsOtpWithLogin, {
                        method: "POST",
                        headers: {
                            "Content-type": "application/json; charset=UTF-8",
                            Authorization: JSON.parse(this.userSession),
                        },
                        body: JSON.stringify({
                            otp: this.fieldValues,
                        }),
                    })
                    .then((response) => response.json())
                    .then((data) => {
                        this.otpStatus = data;
                        this.isButtonEnabled = false;
                        this.fields.map((ele, i) => {
                            document
                                .getElementById("inputField" + i)
                                .classList.remove("active");
                        });

                        if (this.otpStatus.jwt) {

                            localStorage.setItem("user_session", JSON.stringify(this.otpStatus.jwt));

                            $("#pswdVerification").modal("hide");

                            if (this.isSignUpBonus) {
                                $("#bonusActivatedModal").modal("show");
                            } else {
                                $("#signUpSuccessModal").modal("show");
                            }

                        } else {
                            this.error =
                                "Incorrect code,please try again or resend a new code";
                            setTimeout(() => {
                                this.fields.forEach((field) => {
                                    field.value = "";
                                });
                            }, 3000);
                            this.isButtonEnabled = false;
                            this.fields.map((ele, i) => {
                                document
                                    .getElementById("inputField" + i)
                                    .classList.remove("active");
                            });
                        }
                    });
            } catch (error) {
                this.error = "An unknown error occured";
                this.isButtonEnabled = false;
                this.fields.map((ele, i) => {
                    document.getElementById("inputField" + i).classList.remove("active");
                });
            }
        },
        cart_withoutlogin() {
            this.$router.push({
                name: 'cartWithoutLogin'
            });
        },
        route_homepage() {
            sessionStorage.removeItem("newReferredUser");
            localStorage.removeItem("ExistEmail");

            this.userSession = localStorage.getItem("user_session");
            this.$store.dispatch("fetchCartItems", this.userSession);
            const addToCartCheckWithoutLogin = (sessionStorage.getItem("adding_toCart"));
            if (addToCartCheckWithoutLogin === "true") {
                this.call_addto_cart(this.userSession);
                sessionStorage.removeItem("adding_toCart");
            } else {
                this.$router.push({
                    name: "homeLogin"
                })
            }
        },
        display() {
            this.fieldValues = this.fields.map((field) => field.value).join("");
            this.fieldValues = Number(this.fieldValues);
            return this.fieldValues;
        },

        validate() {
            if (this.checklogin_data() && this.check_password()) {
                this.login();
            }
        },
        checklogin_data() {
            this.ExistemailError = ""

            if (this.email === "") {
                this.emailError = "Email is required";
                return false;
            } else if (!this.validateEmailFormat(this.email)) {
                this.emailError = "Invalid email format";
                return false;
            } else {
                this.emailError = "";
                this.error = "";
            }
            return true;
        },
        check_password() {
            if (this.password === "") {
                this.passwordError = "Password is required";
                return false;
            } else if (this.password.length > 30) {
                this.passwordError = "Password must be less than 30 characters";
                return false;
            } else {
                this.passwordError = "";
                this.error = "";
            }
            return true;
        },
        validateEmailFormat(email) {
            const re = /\S+@\S+\.\S+/;
            return re.test(email);
        },
        async send_loginotp() {
            try {
                await fetch(apiURL.generateOtpSmsVerification, {
                        method: "POST",
                        headers: {
                            "Content-type": "application/json; charset=UTF-8",
                            Authorization: this.newData.message.jwt,
                        },
                    })
                    .then((response) => response.json())
                    .then((data) => {
                        this.receivedSms = data;
                        this.$emit("contactNumFromLogin", this.receivedSms.toMobile);
                        // console.log("newDataforSend OTP:", this.receivedSms.toMobile);
                    });
            } catch (error) {
                this.error = error;
            }
        },

        async login() {
            this.isLoading = true;
            this.error = "";
            this.ExistemailError = ""

            try {
                await fetch(apiURL.signIn, {
                        method: "POST",
                        headers: {
                            "Content-type": "application/json; charset=UTF-8",
                        },
                        body: JSON.stringify({
                            email: this.email,
                            password: this.password,
                        }),
                    })
                    .then((response) => response.json())
                    .then((data) => {
                        this.isLoading = false;
                        this.newData = data;

                        if (this.newData.status == 200) {
                            this.snackbar = true;

                            localStorage.setItem("user_session", JSON.stringify(this.newData.data[0].jwt));
                            dataLayer.push({
                                'event': 'sign_in_completed'
                            });
                            this.error = "";
                            localStorage.removeItem("ExistEmail");

                            this.userSession = localStorage.getItem("user_session");
                            this.$store.dispatch("fetchCartItems", this.userSession);
                            const decoded = jwt_decode(this.userSession);
                            this.userId = decoded.user_id
                            const addToCartCheckWithoutLogin = (sessionStorage.getItem("adding_toCart"));

                            if (addToCartCheckWithoutLogin === "true") {

                                this.call_addto_cart(this.userSession);
                                sessionStorage.removeItem("adding_toCart");
                            } else {
                                this.$router.push({
                                    name: "homeLogin"
                                }).then(() => {
                                    if (this.$route.name === "homeLogin") {
                                        $(".modal-backdrop.fade").removeClass("modal-backdrop");
                                    }
                                });

                            }
                        } else if (this.newData.status == 400) {
                            if (this.newData.message.message == "User Not Verified.") {
                                $("#smsModal").modal("show");

                                localStorage.setItem("user_session", JSON.stringify(this.newData.message.jwt));
                                this.send_loginotp();
                            } else {
                                this.error = "Sorry, that login was invalid. Please try again.";
                            }
                        } else if (this.newData === "This Account is Blocked") {
                            this.error = "This account is blocked"
                            this.isLoading = false;
                        } else {
                            this.error = "Sorry, that login was invalid. Please try again.";
                        }
                    });
            } catch (error) {
                this.passwordError = null;
                this.error = this.newData.message;
                this.isLoading = false;
            }
        },
        async login_withgoogle() {
            try {
                await fetch(apiURL.googleLoginData, {
                        method: "POST",
                        headers: {
                            "Content-type": "application/json; charset=UTF-8",
                        },
                    })
                    .then((response) => response.json())
                    .then((data) => {
                        let googleLoginData = data.data;
                        // console.log('googleLoginData:', googleLoginData);
                        window.location.href = googleLoginData.redirectUrl;
                    });
            } catch (error) {
                // console.error(error);
                this.error = "Google Log in failed";
            }
        },
        async login_withfacebook() {
            try {
                await fetch(apiURL.facebookLoginData, {
                        method: "POST",
                        headers: {
                            "Content-type": "application/json; charset=UTF-8",
                        },
                    })
                    .then((response) => response.json())
                    .then((data) => {
                        let facebookLoginData = data.data;
                        //console.log('facebookLoginData:', facebookLoginData);
                        window.location.href = facebookLoginData.redirectUrl;
                    });
            } catch (error) {
                // console.error(error);
                this.error = "Facebook Log in failed";
            }
        },

        call_addto_cart(userSession) {
            this.my_credit(this.userSession);
            this.cart_details = JSON.parse(sessionStorage.getItem('guest_cartDetails'));
            if (this.cart_details) {
                // this.cart_details.forEach(async (item) => {
                //     await this.addto_cart_login(item, userSession);
                // });
                this.addto_cart_login(this.cart_details, userSession);

            } else {
                this.$router.push({
                    name: "homeLogin"
                })
            }

        },

        addto_cart_login(cart_details, userSession) {

            let cartItemsobject = {
                CartItems: cart_details.map(item => ({
                    raffle_id: item.id,
                    numberOftickets: item.entryValue,
                    selectedInstantTickets: []
                }))
            };

            fetch(apiURL.addCartWithoutLoginItems, {
                    method: "POST",
                    headers: {
                        "Content-type": "application/json; charset=UTF-8",
                        Authorization: JSON.parse(userSession),
                    },
                    body: JSON.stringify(cartItemsobject)
                })
                .then((response) => response.json())
                .then((data) => {
                    this.addCartData = data;

                    if (this.addCartData.status == 200) {

                        this.fetchCartCount(this.userSession);
                        // this.my_credit(this.userSession);
                        sessionStorage.removeItem('guest_cartDetails')
                        sessionStorage.removeItem('adding_toCart');
                        this.$router.push({
                            name: 'cartWithLogin'
                        })

                        // Trigger dataLayer and _tfa for analytics
                        cart_details.forEach(item => {
                            dataLayer.push({
                                event: "add_to_cart",
                                ecommerce: {
                                    items: [{
                                        item_id: item.id,
                                        item_name: item.Title,
                                        item_category: item.categoryName,
                                        price: item.entry_cost_gbp,
                                        quantity: item.entryValue
                                    }]
                                }
                            });

                            _tfa.push({
                                notify: 'event',
                                name: 'add_to_cart',
                                id: 1418793,
                                quantity: item.entryValue
                            });
                        });

                        // console.log("dataLayer", dataLayer)
                        // console.log("_tfa", _tfa)

                    } else if (this.addCartData.status == 400) {

                        localStorage.setItem("snackbarinstantNegativeTickets", true);
                        this.$router.push({
                            name: 'cartWithLogin'
                        })
                    }
                });
        },

        async send_link_to_email() {
            try {
                const response = await fetch(apiURL.forgotPassword, {
                    method: "POST",
                    headers: {
                        "Content-type": "application/json; charset=UTF-8",
                    },
                    body: JSON.stringify({
                        "email": this.enteredEmail,
                    }),
                });

                const responseText = await response.text();

                if (response.status === 200) {
                    $("#forgotPswdLinkModal").modal("show");
                    $("#forgotPaswdModal").modal("hide");

                } else {
                    this.enteredEmailError = responseText
                }

            } catch (error) {

            }
        },
        frgtEmailCheck() {
            this.enteredEmailError = ""

            if (this.enteredEmail === "") {
                this.frgtEmailError = "Email is required";
                return false;
            } else if (!this.validateEmailFormat(this.enteredEmail)) {
                this.frgtEmailError = "Invalid email format";
                return false;
            } else {
                this.frgtEmailError = "";
            }
            return true;
        },
    },
};
</script>

<style>
.homeErrorBg {
    border-color: red !important;
}
</style>
