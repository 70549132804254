<template>
  <div class="body-bg loaderBg" :class="{'dark': storedDarkMode}" :style="{'background-color': storedDarkMode ?  '#000' : '#fff'}" id="loading-raffolux" style=" z-index: 1040; overflow: hidden; position: fixed; height: 100vh; width: 100vw; top: 0; right: 0; ">
      <div class="container" style=" display: flex; justify-content: center; top: 40vh; position: relative; flex-direction: column; text-align: center; z-index: 3000;">
          <div>
              <img alt="" class="animate__animated animate__bounce animate__infinite black-light" :style="{ filter: `brightness(${brightnessValue})` }" style="height: 40px;" :src="socialImgUrlLink + 'loading/point.webp'" />
              <img alt="" class="black-light" :style="{ filter: `brightness(${brightnessValue})` }" style="max-width: 200px; position: relative; left: -15px; top: 5px;" :src="socialImgUrlLink + 'loading/text.svg'">
          </div>
      </div>
  </div>
  </template>
  
  <script>
  import {
      socialImgUrlLink
  } from "@/s3bucket.js";
  export default {
      data() {
          return {
              socialImgUrlLink: socialImgUrlLink,
              storedDarkMode: false,
          };
      },
  
      computed: {
          brightnessValue() {
              return this.storedDarkMode ? 1 : 0;
          },
      },
  
      mounted() {
          const storedDarkMode = JSON.parse(localStorage.getItem("isChecked"));
        //   console.log({storedDarkMode});
          if (storedDarkMode === true) {
              this.storedDarkMode = storedDarkMode;
          } else {
          }
      },
  };
  </script>